import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginBg from "src/assets/login-bg.png";
import "src/screens/login/login.css";
import { useStore } from "../../use-stores";
import { isValidEmail, isValidMobile } from "../../utils";
import { NotifyDark } from "../../utils/snippet";

const ForgotpasswordPage: React.FC = () => {
    /**************************************************************INITIAL SETUP */
    const { tokenStore, vendorStore } = useStore();
    const navigate = useNavigate();
    useEffect(() => {
        console.log("inside useeffect of Forgot Password", tokenStore.validToken());
        if (tokenStore.validToken()) {
            navigate("/dashboard");
        }
    }, [tokenStore.token]);
    /**************************************************************OTP */
    const [hiddenForm, setHiddenForm] = useState(false);
    const [isInProcess, setIsInProcess] = useState(false);
    const [userName, setUserName] = useState(["", ""]);
    const sendOTP = async (values: any) => {
        setIsInProcess(true);
        let _post: any = {};
        let _r: boolean = false;
        let _isEmail = await isValidEmail(values.username);
        let _isMobile = await isValidMobile(values.username);
        if (_isEmail) {
            setUserName([values.username, null]);
            _post.email = values.username;
            _r = await vendorStore.sendOTP(_post).then((_e: any) => {
                if (_e && _e.status === 'error') { NotifyDark(_e.status, "Failed", _e.message); return _e.data.isProcess }
                NotifyDark(_e.status, "Success", 'Verification code sent, Please check your device' || _e.message);
                return _e.data.isProcess
            });
        } else if (_isMobile) {
            setUserName([null, values.username]);
            _post.mobile = values.username;
            _r = await vendorStore.sendOTP(_post).then((_e: any) => {
                if (_e && _e.status === 'error') { NotifyDark(_e.status, "Failed", _e.message); return _e.data.isProcess }
                NotifyDark(_e.status, "Success", 'Verification code sent, Please check your device' || _e.message);
                return _e.data.isProcess
            });
        } else {
            NotifyDark("error", "Failed", "Please input valid Email/Mobile");
        }
        setHiddenForm(_r);
        setIsInProcess(_r);
    };
    const verifyOTP = async (values: any) => {
        setIsInProcess(false);
        let _post = {
            email: userName[0],
            mobile: userName[1],
            otp: values.otp,
        };
        let _r: any = await vendorStore.verifyOTP(_post);
        if (_r && _r.status === 'error') {
            NotifyDark(_r.status, "Failed", _r.message || 'Verification Failed, Try again');
            setIsInProcess(false);
        } else NotifyDark(_r.status, "Success", 'Verification Successfully Done' || _r.message);
        if (tokenStore.token && tokenStore.validToken()) {
            navigate("/dashboard");
        }
    };
    const OnFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <div className="web-authform">
            <Row>
                <Col span={12}>
                    <div className="web-authform-left">
                        <img src={LoginBg} alt="login-bg" />
                    </div>
                </Col>
                <Col span={12}>
                    <div className="web-authform-right">
                        <div className="web-authform-container">
                            <h1>Reset Password</h1>
                            <Form hidden={hiddenForm} disabled={isInProcess} onFinish={sendOTP} onFinishFailed={OnFinishFailed} className="login-form" layout="vertical" autoComplete="off">
                                <Form.Item label={<label className="web-custom-label">Email / Phone</label>} name="username" rules={[{ required: true, message: "Please enter your email!" }]}>
                                    <Input className="web-auth-form-input" />
                                </Form.Item>
                                <Button className="web-primary-button" htmlType="submit">
                                    Send OTP
                                </Button>
                            </Form>
                            <Form hidden={!hiddenForm} disabled={!isInProcess} onFinish={verifyOTP} onFinishFailed={OnFinishFailed} layout="vertical" initialValues={{ remember: true }} autoComplete="off" className="login-form">
                                <Form.Item label={<label className="web-custom-label">Enter OTP</label>} name="otp" rules={[{ required: true, message: "Please enter your otp!" }]} >
                                    <Input className="web-auth-form-input" />
                                </Form.Item>
                                <Button className="web-primary-button" htmlType="submit">
                                    Verify OTP
                                </Button>
                            </Form>
                            <p className="web-authform-newacc-text">
                                {hiddenForm && <>Haven't received the code? &nbsp;{" "}
                                    <span>
                                        <a onClick={() => sendOTP({ username: userName[0] || userName[1] })} style={{ color: "var(--Webprimary)" }}>Resend</a>
                                    </span>,&nbsp;{" "}</>}Already have an account ? &nbsp;{" "}
                                <span>
                                    <Link to="/login" style={{ color: "var(--Webprimary)" }}>
                                        Login
                                    </Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ForgotpasswordPage;

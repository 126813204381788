import React, { useState, useEffect } from "react";
import { Row, Col } from 'antd';
import { Observer } from 'mobx-react-lite';
import { useStore } from "../../use-stores";
import { useNavigate } from 'react-router-dom';
import { CardsSection } from './components/cards';
import { ChartSections } from './components/charts';
import { LatestOrders } from './components/latestorders';
import { toJS } from "mobx";
import { PageLayout } from "../../layouts/page/page";
import './dashboard.css';

const DashboardPage: React.FC = () => {
    const { tokenStore, subscriptionStore, analyticalStore } = useStore();
    const user: any = tokenStore.getUser();
    const navigate = useNavigate();
    const [dailyChart, setDailyChart] = useState<any>([]);

    useEffect(() => {
        console.log('inside autorun of Dashboard');
        if (user && !subscriptionStore.isFetching && (!subscriptionStore.subscribedServices || toJS(subscriptionStore.subscribedServices).length === 0)) {
            console.log('getting subscription');
            subscriptionStore.getSubscription(user.vendor_id).then((r: any) => {
                console.log(1, r);
                if (r && r.length <= 0) navigate('/providers')
            });
        }
        if (!toJS(analyticalStore.dailyChart) || toJS(analyticalStore.dailyChart).length === 0) {
            analyticalStore.getDailyGraphData(user.vendor_id).then((_d: any) => {
                setDailyChart(toJS(analyticalStore.dailyChart))
            })
        } else { setDailyChart(toJS(analyticalStore.dailyChart)) }
    }, []);

    return <Observer>{() => {
        return (
            <PageLayout title="Dashboard">
                <Row justify="center"><Col span={24}><CardsSection /></Col></Row>
                <Row gutter={[10, 10]} justify="center">
                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}><ChartSections data={dailyChart} /></Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}><LatestOrders /></Col>
                </Row>
            </PageLayout>
        )
    }}</Observer >
}

export default DashboardPage;
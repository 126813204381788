import { Button, Col, Form, Input, Row, Alert } from "antd";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useStore } from "../../use-stores";
import { isValidEmail, isValidMobile } from "../../utils";
import { NotifyDark } from "../../utils/snippet";
import _ from "lodash";
import "./verification.css";

const VerificationPage: React.FC<any> = () => {
    /**************************************************************INITIAL SETUP */
    const { tokenStore, vendorStore, generalStore } = useStore();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { type, data, notify }: any = state;
    /**************************************************************OTP */
    const [hiddenForm, setHiddenForm] = useState(false);
    const [isInProcess, setIsInProcess] = useState(false);
    const [userName, setUserName] = useState(["", ""]);
    const sendOTP = async (values: any) => {
        let _r: boolean = false;
        try {
            setIsInProcess(true);
            let _post: any = {};
            let _isEmail = await isValidEmail(values.username);
            let _isMobile = await isValidMobile(values.username);
            if (_isEmail) {
                setUserName([values.username, null]);
                _post.email = values.username;
                _r = await vendorStore.sendOTP(_post).then((_e: any) => {
                    if (_e && _e.status === 'error') { NotifyDark(_e.status, "Failed", _e.message); return _e.data.isProcess }
                    NotifyDark(_e.status, "Success", 'Verification code sent, Please check your device' || _e.message);
                    return _e.data.isProcess
                });
            } else if (_isMobile) {
                setUserName([null, values.username]);
                _post.mobile = values.username;
                _r = await vendorStore.sendOTP(_post).then((_e: any) => {
                    if (_e && _e.status === 'error') { NotifyDark(_e.status, "Failed", _e.message); return _e.data.isProcess }
                    NotifyDark(_e.status, "Success", 'Verification code sent, Please check your device' || _e.message);
                    return _e.data.isProcess
                });
            } else {
                NotifyDark("error", "Failed", "Please input valid Email/Mobile");
            }
        } catch (err) { } finally {
            setHiddenForm(_r);
            setIsInProcess(_r);
        }
    };
    const verifyOTP = async (values: any) => {
        setIsInProcess(false);
        let _post = {
            email: userName[0],
            mobile: userName[1],
            otp: values.otp,
        };
        let _r: any = await vendorStore.verifyOTP(_post);
        if (_r && _r.status === 'error') {
            NotifyDark(_r.status, "Failed", _r.message || 'Verification Failed, Try again');
            setIsInProcess(false);
        } else NotifyDark(_r.status, "Success", 'Verification Successfully Done' || _r.message);
        if (tokenStore.token && tokenStore.validToken()) {
            tokenStore.logout();
            navigate("/login");
        }
    };
    const OnFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const logout = () => {
        tokenStore.logout();
        generalStore.setSelectedMenuItem();
        navigate('/');
    }

    return (
        <div className="verifypg web-authform">
            <Row>
                <Col span={24}>
                    <div className="web-authform-right">
                        <div className="web-authform-container">
                            {notify && <Alert
                                message="Verification Pending"
                                description="You can't access this application without email verification. So please verify your email-ID here."
                                type="warning"
                                showIcon
                            />
                            }
                            <br />
                            <h2 className='app-h2-header'>Verify Your {_.startCase(type || 'email')}</h2>
                            <Form hidden={hiddenForm} disabled={isInProcess} onFinish={sendOTP} onFinishFailed={OnFinishFailed} className="login-form" layout="vertical" autoComplete="off" >
                                <Form.Item label={<label className="web-custom-label">{_.startCase(type || 'email')}</label>} name="username" rules={[{ required: true, message: "Please enter your email!" }]} initialValue={data || ''} >
                                    <Input readOnly className="web-auth-form-input" />
                                </Form.Item>
                                <Button className="web-primary-button" htmlType="submit">
                                    Send OTP
                                </Button>
                            </Form>
                            <Form
                                hidden={!hiddenForm}
                                disabled={!isInProcess}
                                layout="vertical"
                                initialValues={{ remember: true }}
                                onFinish={verifyOTP}
                                onFinishFailed={OnFinishFailed}
                                autoComplete="off"
                                className="login-form"
                            >
                                <Form.Item
                                    label={<label className="web-custom-label">Enter OTP</label>}
                                    name="otp"
                                    rules={[{ required: true, message: "Please enter your otp!" }]}
                                >
                                    <Input className="web-auth-form-input" />
                                </Form.Item>
                                <Button className="web-primary-button" htmlType="submit">
                                    Verify OTP
                                </Button>
                            </Form>
                            <p className="web-authform-newacc-text">
                                {hiddenForm && <>Haven't received the code? &nbsp;{" "}
                                    <span>
                                        <a onClick={() => sendOTP({ username: userName[0] || userName[1] })} style={{ color: "var(--Webprimary)" }}>Resend</a>
                                    </span>&nbsp;{" "} Or &nbsp;{" "}</>}
                                {type && type === 'email' ? (<><span>
                                    <a onClick={logout} style={{ color: "var(--Webprimary)" }}>logout</a>
                                </span>
                                    &nbsp;{" "} this account.</>) : (<>go to &nbsp;{" "}<span>
                                        <a href="/profile" style={{ color: "var(--Webprimary)" }}>Account Settings</a>
                                    </span></>)}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default VerificationPage;

import React, { useState } from "react";
import { Button, Col, Divider, Row, Spin } from 'antd';
import { Observer } from 'mobx-react';
import { useStore } from "../../../use-stores";

export const ReviewOrder: React.FC<any> = ({ order, onCompletion, onRevert, orderStatus }) => {
    const { orderStore } = useStore();
    const [pageLoader, setPageLoader] = useState(false)
    const onFinish = async (values: any) => {
        setPageLoader(true);
        orderStore.setIsFormProgress(false);
        await orderStore.doOrder(order).then((_d: any) => {
            onCompletion(_d.data);
            orderStatus(_d);
        }).catch(err => {
            console.error(err);
            orderStatus({ status: 'error', message: 'Something Went Wrong' });
            // TODO: handle order failure
        }).finally(() => {
            setPageLoader(false);
        })
    }

    return <Observer>{() => {
        return (
            <div>
                <Spin spinning={pageLoader} tip="Processing...">
                    <Row>
                        <Col span={12}><h2 className='app-h2-header'>Confirm your details</h2></Col>
                        <Col span={12} className='text-align-end'><h2 className='app-h2-header'>{order.cost ? 'Rs.' + order.cost : ''} </h2></Col>
                    </Row>
                    <Divider />
                    <h3 className="app-h3-header">Pickup Address :</h3>
                    <p>{order.origin.address_line_1}, {order.origin.address_line_2}, {order.origin.city}, {order.origin.state}, {order.origin.country} - {order.origin.postcode}</p>
                    <h3 className="app-h3-header">Drop To Address :</h3>
                    <p>{order.destination.address_line_1}, {order.destination.address_line_2}, {order.destination.city}, {order.destination.state}, {order.destination.country} - {order.destination.postcode}</p>
                    <h3 className="app-h3-header">Pickup Date and Time :</h3>
                    <p>{order.deliverynow ? 'Delivery Now' : order.pickup_datetime}</p>
                    <h3 className="app-h3-header">Vendor Selected :</h3>
                    <p className="firstCap">{order.service_provider}</p>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Button htmlType='reset' onClick={onRevert} className="app-custom-btn-outline" style={{ margin: 'unset', display: 'revert' }}>Back</Button>
                        </Col>
                        <Col span={12} style={{ textAlign: "end" }}>
                            <Button htmlType='submit' onClick={onFinish} className="app-custom-btn" style={{ margin: 'unset', display: 'revert' }}>Next</Button>
                        </Col>
                    </Row>
                </Spin>
            </div>
        )
    }}</Observer>
}
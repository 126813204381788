import { TokenStore } from './tokenstore';
import { observable, action, makeObservable } from 'mobx';
import axios, { AxiosRequestHeaders } from 'axios';
import { SERVICE_URL, GRAPHQL_URL } from '../utils/constants';
import { isNotEmpty } from '../utils';
import { fetchSubscriptionCount, fetchSubscriptions, fetchSubscriptionsWithProvider } from '../utils/queries';

const _ErrorResponse = (_m: any = null, _d: any = null) => {
    return { status: 'error', message: _m && typeof _m === 'string' ? _m : 'Something went wrong, Please try again after sometime.', data: _d }
};
const _SuccessResponse = (_m: any = null, _d: any = null) => {
    return { status: 'success', message: _m && typeof _m === 'string' ? _m : 'Process Successfully Done', data: _d }
};

export class SubscriptionStore {
    /***********************************SUBSCRIPTION */
    subscribedServices: any[] = [];
    isFetching = false;
    /***********************************STEP FORM */

    constructor(private tokenStore: TokenStore) {
        makeObservable(this, {
            subscribedServices: observable,
            isFetching: observable,
            getSubscription: action,
            setSubscribedServices: action,
            setIsFetching: action,
        })
    }
    /***********************************************************************FUNCTIONS */
    private getHeaders(): AxiosRequestHeaders {
        if (this.tokenStore.validToken()) {
            return { 'authorization': `Bearer ${this.tokenStore.getToken()}`, 'x-clientapp': 'portalweb' }
        }
        return { 'x-hasura-role': 'anonymous', 'x-clientapp': 'portalweb' }
    }
    public async getQuote(_post: any) {
        const _self = this;
        try {
            let res: any = await axios.post(`${SERVICE_URL}/api/delivery/quote`, _post, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') return _ErrorResponse(res.data.message || null)
                return _SuccessResponse(null, res.data.data);
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }
    async doSubscription(vendor_id: string, _data: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/vendor/${vendor_id}/subscribe`, _data, { headers: this.getHeaders() })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') _ErrorResponse(res.data.message || null)
                _self.setSubscribedServices(res.data[0])
                return _SuccessResponse(null, res.data[0])
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    async getSubscription(vendor_id: String, queryType = 'Basic', limit = 100, skip = 0) {
        const _self = this;
        try {
            let result = null;
            _self.setIsFetching(true);
            let fetchQuery = fetchSubscriptions;
            if (queryType === 'WithBasicProvider') {
                fetchQuery = fetchSubscriptionsWithProvider;
            }
            let res = await axios.post(GRAPHQL_URL, { query: fetchQuery, variables: { "limit": limit, "offset": skip, "vendor_id": vendor_id } }, { headers: this.getHeaders() })
            _self.setIsFetching(false);
            if (res && res.data && isNotEmpty(res.data.errors)) return _ErrorResponse(res.data.errors[0].message || null)
            if (res && res.data && res.data.data) {
                result = res.data.data.subscribed_services;
                _self.setSubscribedServices(result);
                return _SuccessResponse(null, res.data.data.subscribed_services)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    async getSubscriptionCount(vendor_id: String) {
        try {
            let result = null;
            let res = await axios.post(GRAPHQL_URL, { query: fetchSubscriptionCount, variables: { "vendor_id": vendor_id } }, { headers: this.getHeaders() })
            if (res && res.data && isNotEmpty(res.data.errors)) return _ErrorResponse(res.data.errors[0].message || null)
            if (res && res.data && res.data.data) {
                result = res.data.data.subscribed_services_aggregate;
                return result.subscriptions.count;
            } else return []
        } catch (e) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    /***********************************************************************ACTIONS */
    setSubscribedServices(v: any) {
        this.subscribedServices = v;
    }
    setIsFetching(v: boolean) {
        this.isFetching = v;
    }
}
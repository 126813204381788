import React, { useState, useEffect } from "react";
import { Observer } from 'mobx-react-lite';
import { Card, Row, Col, Badge, Space, List, Skeleton, PageHeader, Divider, Typography, Button, Tooltip } from 'antd';
import { useStore } from "../../../use-stores";
import { isNotBlank } from '../../../utils';
import { useNavigate } from "react-router-dom";
import { providerStyle, statusColor } from "src/utils/snippet";
import { RedoOutlined, ArrowRightOutlined } from "@ant-design/icons";

export const LatestOrders: React.FC = () => {
    const { orderStore, tokenStore } = useStore();
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const user: any = tokenStore.getUser();
    const getToOrders = () => {
        navigate('/orders');
    }
    useEffect(() => {
        if (user && !isLoading && orders.length === 0) {
            setIsLoading(true);
            orderStore.getOrders(user.vendor_id, 5).then((_d: any) => {
                setOrders(_d.data);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [])
    const refresh = () => {
        setIsLoading(true);
        orderStore.getOrders(user.vendor_id, 5).then((_d: any) => {
            setOrders(_d.data);
        }).finally(() => {
            setIsLoading(false);
        })
    }
    return <Observer>{() => {
        return (
            <div className="app-container">
                <div className='row-box'>
                    <div className='col-50'><h2 className="app-h2-header">Latest Orders</h2></div>
                    <div className='col-50-end'>
                        <a href="#" className='dashbrdpg-a-tag' onClick={getToOrders}>View More <ArrowRightOutlined /></a></div>
                </div>

                <List className="dashbrdpg-list" loading={isLoading} dataSource={orders} renderItem={(item: any) => (
                    <div className="dashbrdpg-list-item">
                        <Skeleton avatar loading={isLoading} active>
                            <Card>
                                <div className='row-box'>
                                    <div className='col-50'><h3 className="app-h3-header">Order #{item.req_no}{/*<span><a href="#" className="dashbrdpg-track">Track Now</a></span>*/}</h3></div>
                                    <div className='col-50-end'>
                                        <Badge className="site-badge-count-109 firstCap" count={item.status} style={{ backgroundColor: statusColor(item.status) }} />
                                        <Badge className="site-badge-count-109 firstCap" style={providerStyle(item.service_provider)} count={item.service_provider} />
                                    </div>
                                </div>
                                <div className='row-box'>
                                    <div className='col-50'><b className="dashbrdpg-address">Form:</b> {item.origin.name}</div>
                                    <div className='col-50-end'><b className="dashbrdpg-address">To:</b> {item.destination.name}</div>
                                </div>
                            </Card>
                        </Skeleton>
                    </div>
                )} />
            </div>
        )
    }}</Observer>
}
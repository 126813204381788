import React, { useState } from "react";
import { Row, Col, Steps } from 'antd';
import { Observer } from 'mobx-react-lite';
import './createorder.css';
import { PageLayout } from "src/layouts/page/page";
import { transaction } from "mobx";
import { ParcelCollectionForm } from './components/detailsform';
import { SelectTimeForm } from './components/select-time';
import { SelectServiceProviderForm } from './components/select-vendor';
import { ReviewOrder } from './components/review';
import { ThanksWidget } from './components/thanks';

const CreateOrderPage: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [order, setOrder] = useState({});
    const [isOrderSuccess, setIsOrderSuccess] = useState({ status: 'error' })
    const onStepForward = (order: any) => {
        console.log(currentStep, 'completed', order);
        transaction(() => {
            setOrder(order);
            setCurrentStep(currentStep + 1);
            setIsOrderSuccess(isOrderSuccess)
        });
        if (currentStep === 3) {
            setCurrentStep(currentStep + 1);
            return;
        }
        if (currentStep === 4) {
            console.log('completely finished, next is navigation');
            return;
        }
    }
    const onStepBack = () => {
        if (currentStep === 0) return;
        setCurrentStep(currentStep - 1);
    }
    const setOrderSuccess = (v: any) => {
        setIsOrderSuccess(v)
    }
    const steps = [
        {
            title: 'Enter Details',
            content: <ParcelCollectionForm order={order} onCompletion={onStepForward} />,
        },
        {
            title: 'Delivery Time',
            content: <SelectTimeForm order={order} onCompletion={onStepForward} onRevert={onStepBack} />,
        },
        {
            title: 'Select Vendor',
            content: <SelectServiceProviderForm order={order} onCompletion={onStepForward} onRevert={onStepBack} />,
        },
        {
            title: 'Order Confirmation',
            content: <ReviewOrder order={order} onCompletion={onStepForward} onRevert={onStepBack} orderStatus={setOrderSuccess} />,
        },
        {
            title: 'Thank you',
            content: <ThanksWidget order={order} onCompletion={onStepForward} onRevert={onStepBack} orderStatus={isOrderSuccess} />,
        }
    ];

    return <Observer>{() => {
        return (
            <PageLayout title="Create An Order">
                <Row gutter={[6, 6]} className="orderpg-form">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className="app-container">
                            <Steps current={currentStep}>
                                {steps.map(item => (
                                    <Steps.Step key={item.title} title={item.title} />
                                ))}
                            </Steps>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className="app-container">
                            <div className="steps-content">{steps[currentStep].content}</div>
                        </div>
                    </Col>
                </Row>
            </PageLayout>
        )
    }}</Observer>
}

export default CreateOrderPage;
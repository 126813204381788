import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Skeleton, Space, Empty } from "antd";
import { toJS } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { PageLayout } from "src/layouts/page/page";
import { IWEBProviders, IWEBSubscribOurConnection, IWEBSubscribOwnConnection } from "../../interfaces/web";
import { useStore } from "../../use-stores";
import { NotifyDark, Notify } from "../../utils/snippet";
import { BorzoCredentialForm } from "./components/borzo-credentials";
import { PorterCredentialForm } from "./components/porter-credentials";
import { DunzoCredentialForm } from "./components/dunzo-credentials";
import "./providers.css";

const ProvidersPage: React.FC = () => {

    /********************************************************************Initiating Page */
    const { serviceproviderStore, subscriptionStore, tokenStore } = useStore();
    const user: any = tokenStore.getUser();
    if (user && !serviceproviderStore.isFetching && (!serviceproviderStore.providers || serviceproviderStore.providers.length === 0)) {
        serviceproviderStore.getProviders().then((_d: any) => {
            if (_d && _d.status === "error") NotifyDark(_d.status, "Failed", _d.message);
        });
    }
    if (user && !subscriptionStore.isFetching && (!subscriptionStore.subscribedServices || toJS(subscriptionStore.subscribedServices).length === 0)) {
        subscriptionStore.getSubscription(user.vendor_id).then((_d: any) => {
            if (_d && _d.status === "error") NotifyDark(_d.status, "Failed", _d.message);
        });
    }
    const [credentialQuestModalOpen, setCredentialQuestModalOpen] = useState(false);
    const [credentialCollectionModal, setCredentialCollectionModalOpen] = useState(false);
    const [recordId, setRecordID] = useState<any>(null);
    const [logoUrl, setLogoUrl] = useState<any>(null);
    const [providerName, setproviderName] = useState<any>(null);
    const [disableState, setDisableState] = useState<boolean>(false);
    /********************************************************************Use Our Credential */
    const askCredentialQuestion = (item: IWEBProviders) => {
        setRecordID(item.id);
        setLogoUrl(item.logo_url);
        setproviderName(item.name);
        //setCredentialQuestModalOpen(true);  // ~~~~~~~~ FOR OUR CREDENTIALS FLOW 
        setCredentialCollectionModalOpen(true); // ~~~~~~~~ FOR OUR CREDENTIALS FLOW 
    };
    const useOurCredential = async () => {
        setDisableState(true);
        console.log("inside useOurCredential");
        let _post: IWEBSubscribOurConnection = {
            subscriptions: [
                {
                    vendor_id: user.vendor_id,
                    delivery_service_provider_id: recordId,
                    name: providerName,
                    use_vendor_credential: false
                },
            ],
        };
        await subscriptionStore.doSubscription(user.vendor_id, _post).then((_d: any) => {
            if (_d && _d.status === "error") { NotifyDark(_d.status, "Failed", _d.message); }
            else { Notify(_d.status, "Success", "Successfully subscribed"); }
            return true
        });
        handleCancel();
        subscriptionStore.getSubscription(user.vendor_id).then((_d: any) => {
            if (_d && _d.status === "error") NotifyDark(_d.status, "Failed", _d.message);
        });
        setDisableState(false);
    };
    /********************************************************************Use Own Credential */
    const getVendorCredential = () => {
        setCredentialQuestModalOpen(false);
        setCredentialCollectionModalOpen(true);
    };
    const useOwnCredential = async (values: any) => {
        setDisableState(true);
        console.log("inside useOwnCredential", values);
        let _post: IWEBSubscribOwnConnection = {
            subscriptions: [
                {
                    vendor_id: user.vendor_id,
                    delivery_service_provider_id: recordId,
                    name: providerName,
                    use_vendor_credential: true,
                    credential: {
                        credential: { ...values },
                        is_global: false,
                        pincode: null,
                    },
                },
            ],
        };
        await subscriptionStore.doSubscription(user.vendor_id, _post).then((_d: any) => {
            if (_d && _d.status === "error") { NotifyDark(_d.status, "Failed", _d.message); }
            else { Notify(_d.status, "Success", "Successfully subscribed"); }
            return true
        });
        handleCancel();
        subscriptionStore.getSubscription(user.vendor_id).then((_d: any) => {
            if (_d && _d.status === "error") NotifyDark(_d.status, "Failed", _d.message);
        });
        setDisableState(false);
    };
    /********************************************************************Common */
    const handleCancel = () => {
        setCredentialQuestModalOpen(false);
        setCredentialCollectionModalOpen(false);
    };
    const onFailure = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    /********************************************************************UI */
    const GetButton = observer(({ item }: any) => {
        let isExist: boolean = false;
        toJS(subscriptionStore.subscribedServices) && toJS(subscriptionStore.subscribedServices).length > 0 && toJS(subscriptionStore.subscribedServices).map((_i) => {
            if (item.id === _i.delivery_service_provider_id) return (isExist = true);
        });
        if (isExist) {
            return <Button className="connected-btn">Connected</Button>;
        } else {
            return <Button className="select-btn" onClick={() => { askCredentialQuestion(item); }}>Select</Button>;
        }
    });
    return (
        <Observer>
            {() => {
                return (
                    <>
                        <PageLayout title="Subscribe Delivery Partner">
                            <div className="custom-max-width">
                                <div className="provider-list">
                                    {serviceproviderStore.isFetching ? (
                                        <>
                                            {[...new Array(3)].map((n, i) => (
                                                <div className="providerpg-card-skeleton" style={{ marginBottom: "1.2rem" }} key={i}><Skeleton.Button active={true} /></div>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {toJS(serviceproviderStore.providers) && toJS(serviceproviderStore.providers).length > 0 ? (
                                                <>
                                                    {toJS(serviceproviderStore.providers).map((provider: any) => (
                                                        <div className="providerpg-card" key={provider?.id}>
                                                            <div className="providerpg-left">
                                                                <img src={provider?.logo_url} alt="provider_img" />
                                                                <h2>{provider?.name}</h2>
                                                            </div>
                                                            <div><GetButton item={provider} /></div>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (<Empty description={false} style={{ paddingTop: '80px' }} />)}
                                            {toJS(serviceproviderStore.providers) && toJS(serviceproviderStore.providers).length === 0 && (
                                                <div className="providerpg-card">
                                                    <h4 className="text-align-center">No Delivery Partners Found</h4>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <Modal className="question-modal" visible={credentialQuestModalOpen} onCancel={handleCancel} centered footer={null}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <img className="providerpg-vendorlogo mb-2" src={logoUrl} alt="Vendor Logo" />
                                    <p>You can use Our credential or can use Your Own</p>
                                    <div className="flex-column">
                                        <Button className="app-primary-btn app-primary-btn-outline mb-3" onClick={useOurCredential} disabled={disableState}>
                                            Use Our Credentials
                                        </Button>
                                        <Button className="app-primary-btn" onClick={getVendorCredential} disabled={disableState}>
                                            Use Your Credentials
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                            <Modal visible={credentialCollectionModal} onCancel={handleCancel} footer={null}>
                                <Form onFinish={useOwnCredential} disabled={disableState} onFinishFailed={onFailure} initialValues={{ remember: true }}
                                    name="basic"
                                    layout="vertical"
                                    labelCol={{ span: 24 }}
                                    labelAlign="left"
                                    wrapperCol={{ span: 24 }}
                                    autoComplete="off"
                                >
                                    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                                        <Row justify="center">
                                            <Col><img className="providerpg-vendorlogo mb-2" src={logoUrl} alt="Vendor Logo" /></Col>
                                        </Row>
                                        <DunzoCredentialForm providername={providerName} />
                                        <BorzoCredentialForm providername={providerName} />
                                        <PorterCredentialForm providername={providerName} clientID={user.vendor_id} />
                                        <Row gutter={16}>
                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Button className="app-primary-btn app-primary-btn-outline mb-3" onClick={handleCancel}>Cancel</Button>
                                            </Col>
                                            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Button className="app-primary-btn" htmlType="submit">Connect</Button>
                                            </Col>
                                        </Row>
                                    </Space>
                                </Form>
                            </Modal>
                        </PageLayout>
                    </>
                );
            }}
        </Observer>
    );
};

export default ProvidersPage;

import React from "react";
import { Observer } from "mobx-react-lite";
import { Pie } from '@ant-design/plots';
import { Empty, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const ProvidersAnalytics: React.FC<{ data: any[] }> = ({ data }) => {
    /********************************************************************Initiating Page */
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };
    return (
        <Observer>
            {() => {
                return (
                    <div className="app-container">
                        <h2 className="analyticpg-bar-head app-h2-header">Providers Usage
                            <span className='analyticpg-tooltip'><Tooltip title="This chart helps to compare the providers based on orders"> <ExclamationCircleOutlined /></Tooltip></span>
                        </h2>
                        {data && data.length > 0 ? (<Pie {...config} />) : (<Empty />)}
                    </div>
                );
            }}
        </Observer>
    );
};

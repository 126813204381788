import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Observer } from "mobx-react-lite";
import { PageLayout } from "src/layouts/page/page";
import { useStore } from "../../use-stores";
import { OrderStatusAnalytics } from "./components/statusbased";
import { ProvidersAnalytics } from "./components/providersbased";
import { MonthlyReportAnalytics } from "./components/monthlybased";
import { toJS } from "mobx";
import { barChartQry } from "../../utils/queries";
import _ from "lodash";
import './analytics.css';

const AnalyticsPage: React.FC = () => {
    const { subscriptionStore, tokenStore, analyticalStore } = useStore();
    const user: any = tokenStore.getUser();

    const [barChart, setBarChart] = useState<any>([])
    const [pieChart, setPieChart] = useState<any>([])
    const [lineChart, setLineChart] = useState<any>([])

    const assignData = () => {
        let barChartData = [
            {
                type: 'Cancelled',
                count: analyticalStore.statusChart.cancelled.aggregate.count
            },
            {
                type: 'Completed',
                count: analyticalStore.statusChart.completed.aggregate.count
            },
            {
                type: 'Failed',
                count: analyticalStore.statusChart.failed.aggregate.count
            },
            {
                type: 'OutForDelivery',
                count: analyticalStore.statusChart.outForDelivery.aggregate.count
            },
            {
                type: 'Rejected',
                count: analyticalStore.statusChart.rejected.aggregate.count
            }
        ];
        setBarChart(barChartData);
        let pieChartData: any = []
        subscriptionStore.subscribedServices.map((_item: any, _index: number) => {
            pieChartData.push({
                type: _item.service_provider.name,
                value: analyticalStore.statusChart[_item.service_provider.name].aggregate.count
            })
        })
        setPieChart(pieChartData);
    }

    useEffect(() => {
        if (!toJS(subscriptionStore.subscribedServices) || toJS(subscriptionStore.subscribedServices).length === 0 || !toJS(analyticalStore.statusChart) || toJS(analyticalStore.statusChart).length === 0) {
            subscriptionStore.getSubscription(user.vendor_id, 'WithBasicProvider').then((_d: any) => {
                let providerQry = '';
                subscriptionStore.subscribedServices.map((_i: any, _index: number) => {
                    providerQry += `${_i.service_provider.name}: delivery_requests_aggregate(where: {service_provider: {_eq:"${_i.service_provider.name}"}, vendor_id: {_eq: $vendor_id}}) {
                            aggregate {
                              count
                            }
                        }`
                });
                providerQry = providerQry + `}`;
                let newQry = barChartQry.slice(0, -1) + providerQry;
                console.log('QRY', newQry)
                analyticalStore.getStatusGraphData(user.vendor_id, newQry).then((_d: any) => {
                    assignData()
                });
            })
        } else { assignData() }
        if (!toJS(analyticalStore.lineChart) || toJS(analyticalStore.lineChart).length === 0) {
            analyticalStore.getMonthlyGraphData(user.vendor_id).then((_d: any) => {
                setLineChart(toJS(analyticalStore.lineChart.monthly_graph_function))
            })
        } else { setLineChart(toJS(analyticalStore.lineChart.monthly_graph_function)) }
    }, [])

    return (
        <Observer>
            {() => {
                return (
                    <PageLayout title="Analytics">
                        <Row gutter={[12, 12]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}><MonthlyReportAnalytics data={lineChart} /></Col>
                        </Row>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={16} xxl={18}><OrderStatusAnalytics data={barChart} /></Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
                                <ProvidersAnalytics data={pieChart} />
                            </Col>
                        </Row>
                    </PageLayout>
                );
            }}
        </Observer>
    );
};

export default AnalyticsPage;
import React, { useState, useEffect } from 'react';
import { Row, Form, Col, Input, Divider, Button, Cascader, Select, InputNumber, Space } from 'antd';
import { useStore } from "../../../use-stores";
import { Observer } from 'mobx-react';
import { get } from 'lodash';
import { NotifyDark } from '../../../utils/snippet';
import { ReloadOutlined } from '@ant-design/icons';

const { Option } = Select;

export const ParcelCollectionForm: React.FC<any> = ({ order, onCompletion }) => {
  /***************************************************************************INITIATE PAGE */
  const { generalStore, orderStore } = useStore();
  useEffect(() => {
    generalStore.getCountries().then((ee: any) => {
      if (ee && ee.status === 'error') { NotifyDark(ee.status, 'Failed', ee.message) }
    })
  }, [])
  order = {
    "service_provider": "dunzo",
    "origin": {
      "address_line_1": "13th Main Road, 108, 4th Cross Road, Jakkasandra,",
      "address_line_2": "1st Block Koramangala, HSR Layout 5th Sector,",
      "landmark": "Yamuna Theater",
      "city": "Bengaluru",
      "state": "Karnataka",
      "postcode": "560034",
      "country": "India",
      "geopoint": {
        "coordinates": {
          "lat": 13.098136580462306,
          "lng": 77.38572559673034
        }
      }
    },
    "destination": {
      "address_line_1": "Survey no 45/2, ward. 150, Marathahalli - Sarjapur Rd,",
      "address_line_2": "opposite Iblur, Ambalipura,",
      "landmark": "Sathyam Theater",
      "city": "Bengaluru",
      "state": "Karnataka",
      "postcode": "560102",
      "country": "India",
      "geopoint": {
        "coordinates": {
          "lat": 13.077865725995123,
          "lng": 77.42967032592878
        }
      }
    },
    "sender_contact": {
      "name": "Lavanya",
      "phone": "8675070881"
    },
    "receiver_contact": {
      "name": "Sri",
      "phone": "8675070882"
    },
    "iteminfo": {
      "itemtype": "Document",
      "weight": "12",
      "weightunit": "kg",
      "height": "23",
      "heightunit": "ht",
      "width": "22",
      "widthunit": "cm"
    },
    "notes": null
  }
  const [nOrder, setOrder] = useState(order);
  let origin = get(nOrder, 'origin', {});
  let destination = get(nOrder, 'destination', {});
  let iteminfo = get(nOrder, 'iteminfo', {});
  let sender = get(nOrder, 'sender_contact', {});
  let receiver = get(nOrder, 'receiver_contact', {});

  const [weightUnit, setWeightUnit] = useState(iteminfo.weightunit || 'kg');
  const [heightUnit, setHeightUnit] = useState(iteminfo.heightunit || 'cm');
  const [widthUnit, setWidthUnit] = useState(iteminfo.widthunit || 'cm');
  const [form] = Form.useForm();

  const weight = (
    <Select size='small' style={{ width: 'auto' }} defaultValue={weightUnit} onSelect={setWeightUnit}>
      <Option value="kg">KG</Option>
      <Option value="gram">Gram</Option>
      <Option value="liter">Liter</Option>
      <Option value="ml">Milli Liter</Option>
    </Select>
  );
  const height = (
    <Select size='small' style={{ width: 'auto' }} defaultValue={heightUnit} onSelect={setHeightUnit}>
      <Option value="cm">CM</Option>
      <Option value="inch">Inch</Option>
      <Option value="meter">Meter</Option>
      <Option value="feet">Feet</Option>
    </Select>
  );
  const width = (
    <Select size='small' style={{ width: 'auto' }} defaultValue={widthUnit} onSelect={setWidthUnit}>
      <Option value="cm">CM</Option>
      <Option value="inch">Inch</Option>
      <Option value="meter">Meter</Option>
      <Option value="feet">Feet</Option>
    </Select>
  );
  const pic_countryCode = (
    <Form.Item name="pic_countrycode" noStyle initialValue="+91">
      <Select style={{ width: 70 }}>
        <Select.Option selected value="+91">+91</Select.Option>
      </Select>
    </Form.Item>
  );
  const drp_countryCode = (
    <Form.Item name="drp_countrycode" noStyle initialValue="+91">
      <Select style={{ width: 70 }}>
        <Select.Option selected value="+91">+91</Select.Option>
      </Select>
    </Form.Item>
  );
  /***************************************************************************DETAILED FORM SUBMISSION */
  const onFinish = async (values: any) => {
    orderStore.setIsFormProgress(true)
    let _fAdd = [values.pic_address1, values.pic_address2, values.pic_residence[2], values.pic_residence[1], values.pic_pincode, values.pic_residence[0]]
    let _tAdd = [values.drp_address1, values.drp_address2, values.drp_residence[2], values.drp_residence[1], values.drp_pincode, values.drp_residence[0]]
    let [fromGoecode, toGoecode]: any = await generalStore.getGeoCode([_fAdd.join(','), _tAdd.join(',')])
      .then((d: any) => {
        if (d.status === 'error') { NotifyDark(d.status, 'Failed', d.message); return null }
        return [d.data[0], d.data[1]]
      })
    let _post = {
      origin: {
        address_line_1: values.pic_address1,
        address_line_2: values.pic_address2,
        landmark: values.pic_landmark,
        city: values.pic_residence[2],
        state: values.pic_residence[1],
        postcode: values.pic_pincode,
        country: values.pic_residence[0],
        geopoint: {
          coordinates: {
            lat: fromGoecode?.lat,
            lng: fromGoecode?.lng
          }
        }
      },
      destination: {
        address_line_1: values.drp_address1,
        address_line_2: values.drp_address2,
        landmark: values.drp_landmark,
        city: values.drp_residence[2],
        state: values.drp_residence[1],
        postcode: values.drp_pincode,
        country: values.drp_residence[0],
        geopoint: {
          coordinates: {
            lat: toGoecode?.lat,
            lng: toGoecode?.lng
          }
        }
      },
      iteminfo: {
        itemtype: values.itemtype,
        weight: values.weight,
        weightunit: weightUnit,
        height: values.height,
        heightunit: heightUnit,
        width: values.width,
        widthunit: widthUnit
      },
      sender_contact: {
        name: values.sender_name,
        phone: values.pic_countrycode + '' + values.sender_mobile
      },
      receiver_contact: {
        name: values.receiver_name,
        phone: values.drp_countrycode + '' + values.receiver_mobile
      },
      notes: values.notes
    }
    // order = { ...order, ..._post };
    orderStore.setNewOrder(_post)
    orderStore.setIsFormProgress(false)
    // setOrder(order);
    // console.log(onCompletion)
    onCompletion({ ...order, ..._post });
  }
  /***************************************************************************RESET */
  const resetPickup = () => {
    form.resetFields(["sender_name", "sender_mobile", "pic_residence", "pic_address1", "pic_address2", "pic_landmark", "pic_pincode", "pic_countrycode"]);
  }
  const resetDrop = () => {
    form.resetFields(["receiver_name", "receiver_mobile", "drp_address1", "drp_address2", "drp_landmark", "drp_pincode", "drp_residence", "drp_countrycode"]);
  }
  const resetItemInfo = () => {
    form.resetFields(["weight", "itemtype", "height", "width", "notes"]);
    setWeightUnit('kg');
    setHeightUnit('cm');
    setWidthUnit('cm');
  }
  /***************************************************************************COMMON */
  const onFailure = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const onClear = () => {
    orderStore.setNewOrder({})
    orderStore.setIsFormProgress(false)
    setOrder({});
  }

  return <Observer>{() => {
    return (
      <Form onFinish={onFinish} onFinishFailed={onFailure} name="basic" form={form} layout="vertical" labelCol={{ span: 24 }} labelAlign="left" wrapperCol={{ span: 24 }} initialValues={{ remember: true }} disabled={orderStore.isFormProgress} autoComplete="off">
        <Row gutter={[12, 12]}>
          <Col span={12}><h2 className='app-h2-header'>Enter Pickup Details</h2></Col>
          <Col span={12} className="text-align-end"><button onClick={resetPickup} type='button' className='app-plain-btn'>Reset <ReloadOutlined className='app-icon' /></button></Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Sender Name</label>} name="sender_name" initialValue={sender.name} rules={[{ required: true, message: 'Please enter the Sender Name' }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Sender Mobile</label>} name="sender_mobile" initialValue={sender.phone} rules={[{ required: true, message: 'Please enter the sender mobile number' }]} >
              <InputNumber type="number" addonBefore={pic_countryCode} controls={false} className="app-form-input app-form-number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Street Address Line 1</label>} initialValue={origin.address_line_1} name="pic_address1" rules={[{ required: true, message: 'Please input sender Street Address' }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Street Address Line 2</label>} initialValue={origin.address_line_2} name="pic_address2" rules={[{ required: false }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Land Mark</label>} initialValue={origin.landmark} name="pic_landmark" rules={[{ required: false }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Pincode</label>} name="pic_pincode" initialValue={origin.postcode} rules={[{ required: true, message: 'Please enter the Pickup address Pincode' }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item label={<label className="app-custom-label">Country / State / City</label>} initialValue={[origin.country, origin.state, origin.city]} name="pic_residence" rules={[{ type: 'array', required: true, message: 'Please select your pickup residence!' }]} tooltip="Type the city directly">
              <Cascader showSearch options={generalStore.residence} className="app-form-search-option" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={12}><h2 className='app-h2-header'>Enter Drop Details</h2></Col>
          <Col span={12} className="text-align-end"><button onClick={resetDrop} type='button' className='app-plain-btn'>Reset <ReloadOutlined className='app-icon' /></button></Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Receiver Name</label>} name="receiver_name" initialValue={receiver.name} rules={[{ required: true, message: 'Please enter the Receiver Name' }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Receiver Mobile</label>} name="receiver_mobile" initialValue={receiver.phone} rules={[{ required: true, message: 'Please enter the Receiver mobile number' }]} >
              <InputNumber type="number" addonBefore={drp_countryCode} controls={false} className="app-form-input app-form-number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Street Address Line 1</label>} name="drp_address1" initialValue={destination.address_line_1} rules={[{ required: true, message: 'Please input sender Street Address!' }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Street Address Line 2</label>} name="drp_address2" initialValue={destination.address_line_2} rules={[{ required: false }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Land Mark</label>} name="drp_landmark" initialValue={destination.landmark} rules={[{ required: false }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Pincode</label>} name="drp_pincode" initialValue={destination.postcode} rules={[{ required: true, message: 'Please enter the drop address Pincode' }]} >
              <Input className="app-form-input" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item label={<label className="app-custom-label">Country / State / City</label>} name="drp_residence" initialValue={[destination.country, destination.state, destination.city]} rules={[{ type: 'array', required: true, message: 'Please select your drop residence!' }]} tooltip="Type the city directly" >
              <Cascader showSearch options={generalStore.residence} className="app-form-search-option" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={12}><h2 className='app-h2-header'>Enter Package Details</h2></Col>
          <Col span={12} className="text-align-end"><button onClick={resetItemInfo} type='button' className='app-plain-btn'>Reset <ReloadOutlined className='app-icon' /></button></Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Weight</label>} name="weight" initialValue={iteminfo.weight} rules={[{ required: true, message: 'Please input weight of item!' }]} >
              <InputNumber addonBefore={weight} className="app-form-input app-form-number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Type</label>} name="itemtype" initialValue={iteminfo.itemtype} rules={[{ required: true, message: 'Please input type of item!' }]} >
              <Select showSearch style={{ display: 'block' }} className="app-form-input">
                <Option value="Documents">Documents</Option>
                <Option value="Food">Food</Option>
                <Option value="Groceries">Groceries</Option>
                <Option value="Medicine">Medicine</Option>
                <Option value="Electronics">Electronics</Option>
                <Option value="Meat">Meat</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Height</label>} name="height" initialValue={iteminfo.height} rules={[{ required: true, message: 'Please input height of item!' }]} >
              <InputNumber addonBefore={height} className="app-form-input app-form-number" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label={<label className="app-custom-label">Width</label>} name="width" initialValue={iteminfo.width} rules={[{ required: true, message: 'Please input widht of item!' }]} >
              <InputNumber addonBefore={width} className="app-form-input app-form-number" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row justify='start'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item label={<label className="app-custom-label">Notes</label>} name="notes" initialValue={nOrder.notes} rules={[{ required: false }]} >
              <Input.TextArea className="app-form-textarea" rows={4} style={{ paddingTop: ".5rem" }} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Button htmlType='reset' onClick={onClear} className="app-custom-btn-outline" style={{ margin: 'unset', display: 'revert' }}>Clear</Button>
          </Col>
          <Col span={12} style={{ textAlign: "end" }}>
            <Button htmlType='submit' className="app-custom-btn" style={{ margin: 'unset', display: 'revert' }}>Next</Button>
          </Col>
        </Row>
        <Space></Space>
      </Form>
    )
  }}</Observer>
}



/********************************************** BACKUP */
/*lat: 13.098136580462306,
  lng: 77.38572559673034
  lat: 13.077865725995123,
  lng: 77.42967032592878 */
/*13th Main Road, 108, 4th Cross Road, Jakkasandra, 1st Block Koramangala, HSR Layout 5th Sector, Bengaluru, Karnataka 560034*/
/*Survey no 45/2, ward. 150, Marathahalli - Sarjapur Rd, opposite Iblur, Ambalipura, Bellandur, Bengaluru, Karnataka 560102*/
/********************************************** */
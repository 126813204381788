import { TokenStore } from './tokenstore';
import { observable, action, makeObservable } from 'mobx';
import axios, { AxiosRequestHeaders } from 'axios';
import { SERVICE_URL, GRAPHQL_URL } from '../utils/constants';
import { fetchOrders } from '../utils/queries';
import { isNotEmpty } from '../utils';
const _ErrorResponse = (_m: any = null, _d: any = null) => {
    return { status: 'error', message: _m && typeof _m === 'string' ? _m : 'Something went wrong, Please try again after sometime.', data: _d }
};
const _SuccessResponse = (_m: any = null, _d: any = null) => {
    return { status: 'success', message: _m && typeof _m === 'string' ? _m : 'Process Successfully Done', data: _d }
};

export class OrderStore {
    /***********************************STEP FORM */
    newOrder: any = null
    isFormProgress: boolean = false
    myOrders: any = []
    /***********************************ALL ORDERS */
    allOrders: any = []
    isOrderLoading: boolean = false

    constructor(private tokenStore: TokenStore) {
        makeObservable(this, {
            newOrder: observable,
            isFormProgress: observable,
            myOrders: observable,
            allOrders: observable,
            isOrderLoading: observable,
            setIsFormProgress: action,
            setNewOrder: action,
            setMyOrders: action,
            setAllOrders: action,
            setIsOrderLoading: action,
        })
    }
    /***********************************************************************FUNCTIONS */
    private getHeaders(): AxiosRequestHeaders {
        if (this.tokenStore.validToken()) {
            return { 'authorization': `Bearer ${this.tokenStore.getToken()}`, 'x-clientapp': 'portalweb' }
        }
        return { 'x-hasura-role': 'anonymous', 'x-clientapp': 'portalweb' }
    }
    public async doOrder(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/delivery/create`, _post, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') return { status: 'error', message: res.data.message || 'Something went wrong, Please try again after sometime.' }
                _self.getOrders(_post.vendor_id);
                return _SuccessResponse(null, res.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }
    public async getOrders(vendor_id: String, limit = 5, skip = 0) {
        const _self = this;
        try {
            _self.setIsOrderLoading(true);
            let res = await axios.post(GRAPHQL_URL,
                { query: fetchOrders, variables: { vendor_id: vendor_id, "limit": limit, "offset": skip } },
                { headers: _self.getHeaders() })
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
                //throw new Error(res.data.errors[0].message);
            }
            if (res && res.data) {
                _self.setAllOrders(res.data.data.delivery_requests, skip > 0 ? 'push' : '')
                return _SuccessResponse(null, res.data.data.delivery_requests)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        } finally {
            _self.setIsOrderLoading(false);
        }
    }
    public async doCancel(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/delivery/${_post.req_id}/cancel`, { reason: _post.reason }, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') return _ErrorResponse(res.data.message || null)
                _self.setMyOrders(res.data)
                return _SuccessResponse(null, res.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }
    public async doTrack(_req_no: number) {
        const _self = this;
        try {
            let res = await axios.get(`${SERVICE_URL}/api/delivery/${_req_no}/status`, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') return _ErrorResponse(res.data.message || null)
                return _SuccessResponse(null, res.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }
    public async doRetry(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/delivery/retry`, { order_id: _post.order_id }, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (res.data[0].status && res.data[0].status === 'failed') return _ErrorResponse(res.data[0].error || res.data[0].message || null)
                _self.getOrders(_post.vendor_id);
                return _SuccessResponse(null, res.data[0])
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }
    /***********************************************************************ACTIONS */
    setIsFormProgress(v: boolean) {
        this.isFormProgress = v
    }
    setNewOrder(v: object) {
        this.newOrder = {
            ...this.newOrder,
            ...v
        }
    }
    setMyOrders(d: any) {
        this.myOrders.push(d);
    }
    setAllOrders(d: any, assignType?: any, index?: number) {
        if (isNotEmpty(d) && assignType && assignType === 'update' && index && index > -1) {
            this.myOrders[index].status = d;
        } else if (isNotEmpty(d) && assignType && assignType === 'push') {
            this.allOrders.push(...d)
        } else {
            this.allOrders = d;
        }
    }
    setIsOrderLoading(v: boolean) {
        this.isOrderLoading = v;
    }
}
import { TokenStore } from './tokenstore';
import { observable, action, makeObservable } from 'mobx';
import axios, { AxiosRequestHeaders } from 'axios';
import { GRAPHQL_URL } from '../utils/constants';
import { isNotEmpty } from '../utils';
import { lineChartQry, dailyChartQry } from '../utils/queries';

const _ErrorResponse = (_m: any = null, _d: any = null) => {
    return { status: 'error', message: _m && typeof _m === 'string' ? _m : 'Something went wrong, Please try again after sometime.', data: _d }
};
const _SuccessResponse = (_m: any = null, _d: any = null) => {
    return { status: 'success', message: _m && typeof _m === 'string' ? _m : 'Process Successfully Done', data: _d }
};

export class AnalyticalStore {
    /***********************************STEP FORM */
    statusChart: any = []
    lineChart: any = []
    dailyChart: any = []

    constructor(private tokenStore: TokenStore) {
        makeObservable(this, {
            statusChart: observable,
            lineChart: observable,
            dailyChart: observable,
            setStatusChart: action,
            setLineChart: action,
            setDailyChart: action
        })
    }
    /***********************************************************************FUNCTIONS */
    private getHeaders(): AxiosRequestHeaders {
        if (this.tokenStore.validToken()) {
            return { 'authorization': `Bearer ${this.tokenStore.getToken()}`, 'x-clientapp': 'portalweb' }
        }
        return { 'x-hasura-role': 'anonymous', 'x-clientapp': 'portalweb' }
    }

    public async getStatusGraphData(vendor_id: String, barChartQry: String) {
        const _self = this;
        try {
            let res = await axios.post(GRAPHQL_URL,
                { query: barChartQry, variables: { vendor_id: vendor_id } },
                { headers: _self.getHeaders() })
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                _self.setStatusChart(res.data.data)
                return _SuccessResponse(null, res.data.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    public async getMonthlyGraphData(vendor_id: String) {
        const _self = this;
        try {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let res = await axios.post(GRAPHQL_URL,
                { query: lineChartQry, variables: { vendor_id: vendor_id, timezone: timezone || "UTC" } },
                { headers: _self.getHeaders() })
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                _self.setLineChart(res.data.data)
                return _SuccessResponse(null, res.data.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    public async getDailyGraphData(vendor_id: String) {
        const _self = this;
        try {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let res = await axios.post(GRAPHQL_URL,
                { query: dailyChartQry, variables: { vendor_id: vendor_id, timezone: timezone || "UTC" } },
                { headers: _self.getHeaders() })
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                _self.setDailyChart(res.data.data.daily_graph_function)
                return _SuccessResponse(null, res.data.data.daily_graph_function)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    /***********************************************************************ACTIONS */
    setStatusChart(v: any) {
        this.statusChart = v
    }
    setLineChart(v: any) {
        this.lineChart = v
    }
    setDailyChart(v: any) {
        this.dailyChart = v
    }
}
import React, { useState, useEffect } from "react";
import { Button, Avatar, List, Skeleton, Row, Col, Divider } from 'antd';
import { Observer } from 'mobx-react';
import { useStore } from "../../../use-stores";
import { toJS } from "mobx";
import { NotifyDark } from '../../../utils/snippet';
import { ForwardOutlined } from '@ant-design/icons';

export const SelectServiceProviderForm: React.FC<any> = ({ order, onCompletion, onRevert }) => {
    /***********************************************************************************INITIAT PAGE */
    const { orderStore, tokenStore, subscriptionStore, serviceproviderStore } = useStore();
    const [newQuote, setNewQuote] = useState(null);
    const [priceLoader, setPriceLoader] = useState(true);
    //const [suggestionLoader, setSuggestionLoader] = useState(true); // ~~~~~~~~ FOR OUR CREDENTIALS FLOW
    let user = tokenStore.getUser();

    useEffect(() => {
        console.log('inside useeffect of providers')
        try {
            subscriptionStore.getQuote({ vendor_id: user.vendor_id, ...order }).then((_d: any) => {
                if (_d && _d.status === 'error') NotifyDark(_d.status, _d.message);
                setNewQuote(_d.data);
            });
            // ~~~~~~~~ FOR OUR CREDENTIALS FLOW
            //serviceproviderStore.getSuggestQuote({ vendor_id: user.vendor_id, ...order }).finally(() => setSuggestionLoader(false))
            if (user && !subscriptionStore.isFetching && (!subscriptionStore.subscribedServices || toJS(subscriptionStore.subscribedServices).length === 0)) {
                console.log('trying to get the subscriptions')
                subscriptionStore.getSubscription(user.vendor_id, "WithBasicProvider")
            }
        } catch (err) { console.error(err); } finally {
            setPriceLoader(false)
        }
    }, [])
    /***********************************************************************************FORM SUBMISSION */
    const onFinish = (value: any) => {
        orderStore.setIsFormProgress(true);
        let _post = {
            service_provider: value.service_provider?.name || value.provider.name,
            service_provider_id: value.id || null,
            delivery_service_provider_id: value.delivery_service_provider_id || value.provider.id,
            cost: value.cost,
            vendor_id: user.vendor_id
        }
        // orderStore.setNewOrder(_post)
        // orderStore.setCurrentStep(orderStore.currentStep + 1)
        orderStore.setIsFormProgress(false);
        onCompletion({ ...order, ..._post });
    }

    return <Observer>{() => {
        return (
            <div>
                <h2 className='app-h2-header'>Subscribed Services</h2>
                <List className="orderpg-loadmore-list" itemLayout="horizontal"
                    loading={subscriptionStore.isFetching}
                    dataSource={toJS(subscriptionStore.subscribedServices)}
                    renderItem={item => (
                        <div className="orderpg-serviceProvider">
                            <List.Item actions={[<Button block shape="round" className="app-custom-btn" onClick={() => onFinish({ ...item, cost: newQuote && newQuote[item.service_provider.name]['cost'] > 0 ? newQuote[item.service_provider.name]['cost'] : 0 })} loading={priceLoader} >
                                {newQuote && newQuote[item.service_provider.name]['cost'] > 0 ? `Rs.` + Number(newQuote[item.service_provider.name]['cost']).toFixed(2) : `Order`} <ForwardOutlined />
                            </Button>]} >
                                <Skeleton avatar title={false} loading={subscriptionStore.isFetching} active>
                                    <List.Item.Meta
                                        avatar={<Avatar shape="square" className="orderpg-img-box" src={item.service_provider?.logo_url} />}
                                        description={<h2>{item.service_provider?.label}</h2>}
                                    />
                                </Skeleton>
                            </List.Item>
                        </div>
                    )}
                />
                {/* ~~~~~~~~ FOR OUR CREDENTIALS FLOW 
                <Divider />
                <h2 className='app-h2-header'>Suggestions</h2>
                <List className="orderpg-loadmore-list" itemLayout="horizontal"
                    loading={suggestionLoader}
                    dataSource={toJS(serviceproviderStore.suggestQuote)}
                    renderItem={item => (
                        <div className="orderpg-serviceProvider">
                            <List.Item actions={[<Button block shape="round" className="app-custom-btn-outline" onClick={() => onFinish(item)} loading={priceLoader} >
                                {item.cost && item.cost > 0 ? `Rs.` + Number(item.cost).toFixed(2) : `Order`} <ForwardOutlined />
                            </Button>]} >
                                <Skeleton avatar title={false} loading={suggestionLoader} active>
                                    <List.Item.Meta
                                        avatar={<Avatar shape="square" className="orderpg-img-box" src={item.provider?.logo_url} />}
                                        description={<h2>{item.provider?.label}</h2>}
                                    />
                                </Skeleton>
                            </List.Item>
                        </div>
                    )}
                    />*/}

                <Row justify="center">
                    <Col span={24}>
                        <Button htmlType='reset' onClick={onRevert} className="app-custom-btn-outline app-btn-alter">Back</Button>
                    </Col>
                </Row>
            </div>
        )
    }}</Observer>
}
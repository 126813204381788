import React, { useEffect, useState } from 'react';
import { Timeline, List, Button } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useStore } from "../../../use-stores";
import moment from 'moment';
import { Observer } from 'mobx-react-lite';
import { statusColor } from '../../../utils/snippet';
import { NotifyDark } from "../../../utils/snippet";

export const OrderTimeLine: React.FC<{ req_no: number }> = ({ req_no }) => {
    const { orderStore } = useStore();
    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState<any>([]);
    const [trackingURL, setTrackingURL] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        orderStore.doTrack(req_no).then(_d => {
            if (_d && _d.status === 'error') { NotifyDark(_d.status, "Failed", _d.message); return false }
            else { setHistory(_d.data.history); setTrackingURL(_d.data.tracking_url); console.log('kkkkkkkkkkkkkkk', trackingURL) }
        }).finally(() => { setIsLoading(false); })
    }, [req_no])

    return <Observer>{() => {
        return (
            <Timeline>
                <h2 className="app-h2-header listpg-trackbox">Date Based Report</h2>
                <List loading={isLoading} dataSource={history} renderItem={((item: any) => (
                    item.status &&
                    <Timeline.Item color={statusColor(item.status)}>
                        <p className='firstCap'>{item.status}</p>
                        <p>{moment(item.time).format('Do MMM YY [at] hh:mm A')}</p>
                    </Timeline.Item>
                ))} />
                {trackingURL && <Button href={trackingURL} target='_blank' className='app-custom-btn'>Live Track <EnvironmentOutlined /></Button>}
            </Timeline>
        )
    }}</Observer>
}
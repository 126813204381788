import React from "react";
import { Result, Button } from 'antd';
import { Observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";

export const ThanksWidget: React.FC<any> = ({ order, onCompletion, orderStatus }) => {
    const navigate = useNavigate();
    const onFinish = () => {
        navigate(`/orders`)
    }
    const tryAgain = () => {
        window.location.reload();
        navigate(`/createorder`)
    }

    return <Observer>{() => {
        return (
            <div>
                {orderStatus.status === 'success' ? (
                    <Result
                        status="success"
                        title="Successfully Placed Delivery Order"
                        subTitle={`Order number: ${order.req_no}`}
                        extra={[
                            <Button key="console" onClick={onFinish} className="app-custom-btn" style={{ margin: 'unset', display: 'revert' }}>
                                Explore More
                            </Button>
                        ]}
                    />
                ) : (
                    <Result
                        status="error"
                        title={orderStatus.message || 'Something Went Wrong'}
                        extra={[
                            <Button key="console" onClick={tryAgain} className="app-custom-btn" style={{ margin: 'unset', display: 'revert' }}>
                                Try again
                            </Button>
                        ]}
                    />
                )}
            </div>
        )
    }}</Observer>
}
import React from "react";
import { Observer } from "mobx-react-lite";
import { Column } from '@ant-design/plots';
import { Empty, Tooltip } from 'antd';
import { statusColor } from '../../../utils/snippet';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const OrderStatusAnalytics: React.FC<{ data: any[] }> = ({ data }) => {
    /********************************************************************Initiating Page */
    const config: any = {
        data,
        xField: 'type',
        yField: 'count',
        color: (type: any) => {
            return statusColor(type.type);
        },
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            type: {
                alias: 'Status',
            },
            count: {
                alias: 'No.of Orders',
            },
        },
    };

    return (
        <Observer>
            {() => {
                return (
                    <div className="app-container">
                        <h2 className="analyticpg-bar-head app-h2-header">Providers Usage
                            <span className='analyticpg-tooltip'><Tooltip title="This chart helps to compare orders based on status"> <ExclamationCircleOutlined /></Tooltip></span>
                        </h2>
                        {data && data.length > 0 ? (<Column {...config} />) : (<Empty />)}
                    </div>
                );
            }}
        </Observer>
    );
};

import { AlignRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import { Observer } from "mobx-react-lite";
import Logo from "src/assets/logo.svg";
import "src/components/css/navbar.css";
import { useStore } from "../use-stores";

const { Header } = Layout;

function Navbar() {
    const { generalStore } = useStore();
    const showMenu = () => {
        generalStore.showResponsive();
    };
    const getToMainSection = () => {
        document.getElementById("shHomeMain")!.scrollIntoView({
			behavior: 'smooth', // gives an ease-in-out effect to our scroll
		});
    }
    const getToFeature = () => {
        document.getElementById("shFeature")!.scrollIntoView({
			behavior: 'smooth', // gives an ease-in-out effect to our scroll
		});
    }
    const getToContactUs = () => {
        document.getElementById("shContactUs")!.scrollIntoView({
			behavior: 'smooth', // gives an ease-in-out effect to our scroll
		});
    }
    return (
        <Observer>
            {() => {
                return (
                    <>
                        <Header>
                            <div className="header-logo">
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="home-bars" onClick={showMenu}>
                                <AlignRightOutlined />
                            </div>
                            <div className="header-menu">
                                <div className="header-menu-link link-active" onClick={getToMainSection}>Home</div>
                                <div className="header-menu-link link-active" onClick={getToFeature}>Features</div>
                                <div className="header-menu-link link-active" onClick={getToContactUs}>Contact us</div>
                            </div>
                            <div className="header-menu-btn">
                                <Button className="home-login-btn" style={{ marginRight: "10px" }} href="/login">
                                    Login
                                </Button>
                                <Button className="home-signup-btn" href="/signup">
                                    Sign up
                                </Button>
                            </div>
                            {!generalStore.isNavOpen && (
                                <div className="responsive-navbar">
                                    <div className="close-sidebar" onClick={showMenu}>
                                        <CloseCircleOutlined />
                                    </div>
                                    <div className="responsive-menu">
                                        <div className="responsive-menu-link" onClick={getToMainSection}>Home</div>
                                        <div className="responsive-menu-link" onClick={getToFeature}>Features</div>
                                        <div className="responsive-menu-link" onClick={getToContactUs}>Contact us</div>
                                    </div>
                                </div>
                            )}
                        </Header>
                    </>
                );
            }}
        </Observer>
    );
}

export default Navbar;

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ProtectedRoutes from "./components/ProtectedRoutes";
import CreateOrderPage from "./screens/createorder/createorder";
import DashboardPage from "./screens/dashboard/dashboard";
import FaqPage from "./screens/faq/faq";
import ForgotpasswordPage from "./screens/forgotpassword/forgotpassword";
import HomePage from "./screens/home/home";
import ListOrderPage from "./screens/listorder/listorder";
import LoginPage from "./screens/login/login";
import ProvidersPage from "./screens/providers/providers";
import SignupPage from "./screens/signup/signup";
import ProfilePage from "./screens/profile/profile";
import AnalyticsPage from "./screens/analytics/analytics";
import VerificationPage from "./screens/verification/verification";
import AccountPage from "./screens/account/account";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/signup" element={<SignupPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgotpassword" element={<ForgotpasswordPage />} />
                    <Route element={<ProtectedRoutes />}>
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/providers" element={<ProvidersPage />} />
                        <Route path="/createorder" element={<CreateOrderPage />} />
                        <Route path="/orders" element={<ListOrderPage />} />
                        <Route path="/order/:reqno" element={<ListOrderPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/analytics" element={<AnalyticsPage />} />
                        <Route path="/verifyaccount" element={<VerificationPage />} />
                        <Route path="/account" element={<AccountPage />} />
                    </Route>
                    <Route path="/faq" element={<FaqPage />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;

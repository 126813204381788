import { notification } from 'antd';
import { IWEBAlertsType } from '../interfaces/web';

export const Notify = (type: IWEBAlertsType, title: string, message?: string) => {
    notification[type]({
        message: title,
        description: message || null,
        duration: 5,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
};
export const NotifyAndStay = (type: IWEBAlertsType, title: string, message?: string) => {
    let color = '#ffffff';
    if (type === 'error') { color = '#ff9494' } else if (type === 'success') { color = '#b1db67' }
    notification[type]({
        message: title,
        description: message || null,
        duration: 0,
        style: { backgroundColor: color },
        onClick: () => {
            console.log('Notification Clicked!');
        },
        key: 'all'
    });
};
export const NotifyDark = (type: IWEBAlertsType, title: string, message?: string) => {
    let color = '#ffffff';
    if (type === 'error') { color = '#ff9494' } else if (type === 'success') { color = '#b1db67' } else if (type === 'warning') { color = '#efcf5d' }
    notification[type]({
        message: title,
        description: message || null,
        duration: 7,
        style: { backgroundColor: color },
        onClick: () => {
            console.log('Notification Clicked!');
        },
        key: 'all'
    });
};
export const canCancel = (v: string) => {
    let status = ['created', 'submitted', 'agent_assigned', 'reached_pickup'];
    if (status.find((_d) => _d === v)) {
        return true
    } else {
        return false
    }
}
export const canRetry = (v: string) => {
    let status = ['failed'];
    if (status.find((_d) => _d === v)) {
        return true
    } else {
        return false
    }
}
export const statusColor = (v: string) => {
    let color = '#9d9d9d';
    switch (v.toLocaleLowerCase()) {
        case 'created':
            color = '#52c41a';
            break;
        case 'cancelled':
            color = '#e50e0e';
            break;
        case 'failed':
            color = '#970a00';
            break;
        case 'completed':
            color = '#009688';
            break;
        case 'outfordelivery':
            color = '#e7d10d';
            break;
        case 'rejected':
            color = '#795548';
            break;
        default:
            color = '#9d9d9d';
            break;
    }
    return color;
}
export const providerStyle = (v: string) => {
    let _a = {
        color: 'white',
        backgroundColor: '#0b1a58'
    }
    switch (v.toLocaleLowerCase()) {
        case 'borzo':
            _a.backgroundColor = '#8a4af3';
            break;
        case 'dunzo':
            _a.backgroundColor = '#028352';
            break;
        case 'porter':
            _a.backgroundColor = '#4a36ee';
            break;
    }
    return _a;
}
import React, { useState, useEffect } from "react";
import { Button, Cascader, Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { autorun } from "mobx";
import { Observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import { IWEBRegistration } from "../../../interfaces/web";
import { useStore } from "../../../use-stores";
import { NotifyDark } from "../../../utils/snippet";

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
        },
    },
};

const prefixSelector = (
    <Form.Item name="countrycode" noStyle initialValue="+91">
        <Select style={{ width: 70 }}>
            <Select.Option selected value="+91">
                +91
            </Select.Option>
        </Select>
    </Form.Item>
);

export const SignUpGrid: React.FC = () => {
    /*******************************************************************************INITIAT PAGE */
    const { vendorStore, generalStore, tokenStore } = useStore();
    useEffect(() => {
        generalStore.getCountries().then((_e: any) => {
            if (_e && _e.status === "error") {
                NotifyDark(_e.status, "Failed", _e.message);
            }
        });
    }, []);
    const navigate = useNavigate();
    const disposer = autorun(() => {
        console.log("inside autorun of Singupform", vendorStore.registrationData);
        if (vendorStore.registrationData && !tokenStore.validToken()) {
            navigate("/login");
        } else if (tokenStore.validToken()) {
            navigate("/dashboard");
        }
    });
    useEffect(() => {
        console.log("inside use effect of SignUpGrid");
        return () => {
            disposer();
        };
    });
    /*******************************************************************************SUBMIT FORM */
    const [formProcess, setFormProcess] = useState<boolean>(false)
    const onFinish = async (values: IWEBRegistration) => {
        try {
            setFormProcess(true)
            const postData = {
                ...values,
                city: values.residence[2],
                state: values.residence[1],
                country: values.residence[0],
                mobile: values.mobile.toString(),
                countrycode: values.countrycode,
                role: "Owner",
            };
            let res = await vendorStore.doRegister(postData).then((_e: any) => {
                setFormProcess(false)
                if (_e && _e.status === 'error') {
                    NotifyDark(_e.status, "Failed", _e.message);
                    return false
                }
                NotifyDark(_e.status, "Success", "Registration Successfully Completed" || _e.message);
                return true
            });
            if (res) navigate("/verifyaccount", {
                state: { type: "email", data: values.email }
            });
        } catch (err) { console.error(err) }
    };
    const onFailure = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Observer>
            {() => {
                return (
                    <div>
                        <Form onFinish={onFinish} onFinishFailed={onFailure} disabled={formProcess} initialValues={{ remember: true }} name="basic" layout="vertical" labelCol={{ span: 24 }} labelAlign="left"
                            wrapperCol={{ span: 24 }}
                            autoComplete="off"
                        >
                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="First Name"
                                        name="firstname"
                                        rules={[{ required: true, message: "Please input your First Name!" }]}
                                    >
                                        <Input className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Last Name"
                                        name="lastname"
                                        rules={[{ required: true, message: "Please input your Last Name!" }]}
                                    >
                                        <Input className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Company Name"
                                        name="companyname"
                                        rules={[{ required: true, message: "Please input your Company Name!" }]}
                                    >
                                        <Input className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, type: "email", message: "Please input your Email!" }]}
                                    >
                                        <Input className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="mobile"
                                        label="Mobile Number"
                                        rules={[{ required: true, message: "Please input your mobile number!" }]}
                                        className="web-auth-form-line-item"
                                    >
                                        <InputNumber controls={false} type="number" addonBefore={prefixSelector} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Address Line 1"
                                        name="address_line1"
                                        rules={[{ required: true, message: "Please input your Address Line 1!" }]}
                                    >
                                        <Input className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item label="Address Line 2" name="address_line2">
                                        <Input className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[12, 12]}>
                                <Col span={16}>
                                    <Form.Item name="residence" label="Country / State / City" rules={[{ type: "array", required: true, message: "Please select your habitual residence!" }]} >
                                        <Cascader showSearch options={generalStore.residence} className="web-auth-form-search-option" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Pincode"
                                        name="pincode"
                                        rules={[{ required: true, message: "Please input your username!" }]}
                                    >
                                        <Input className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[12, 12]}>
                                <Col span={12}>
                                    <Form.Item label="Password" name="password" rules={[{ min: 8, required: true, message: "Please input your password. Minimum 8 characters!" }]} >
                                        <Input.Password max={8} className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirmpassword"
                                        dependencies={["password"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input your password!",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("The two passwords that you entered do not match!"));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password className="web-auth-form-input" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="agreement"
                                        valuePropName="checked"
                                        rules={[
                                            {
                                                validator: (_, value) =>
                                                    value ? Promise.resolve() : Promise.reject(new Error("Should accept agreement")),
                                            },
                                        ]}
                                        {...tailFormItemLayout}
                                    >
                                        <Checkbox>
                                            I have read the{" "}
                                            <a href="https://shiphyper-web-dev.netlify.app/" style={{ color: "var(--Webprimary)" }}>
                                                agreement
                                            </a>
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button className="web-primary-button" htmlType="submit">
                                SIGN UP
                            </Button>
                        </Form>
                        <p className="web-authform-newacc-text">
                            Already have an account ?{" "}
                            <span>
                                &nbsp;{" "}
                                <Link to="/login" style={{ color: "var(--Webprimary)" }}>
                                    Login
                                </Link>
                            </span>
                        </p>
                    </div>
                );
            }}
        </Observer>
    );
};

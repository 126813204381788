import tokenStore, { TokenStore } from "./tokenstore";
import { VendorStore } from "./vendorstore";
import { GeneralStore } from "./generalstore";
import { ServiceProviderStore } from "./serviceproviderstore";
import { OrderStore } from "./orderstore";
import { SubscriptionStore } from "./subscriptionstore";
import { AnalyticalStore } from "./analyticalstore";

export type RootStore = {
    tokenStore: TokenStore,
    vendorStore: VendorStore,
    generalStore: GeneralStore,
    serviceproviderStore: ServiceProviderStore,
    orderStore: OrderStore,
    subscriptionStore: SubscriptionStore,
    analyticalStore: AnalyticalStore
}

const rootStore = {
    tokenStore,
    vendorStore: new VendorStore(tokenStore),
    generalStore: new GeneralStore(tokenStore),
    serviceproviderStore: new ServiceProviderStore(tokenStore),
    orderStore: new OrderStore(tokenStore),
    subscriptionStore: new SubscriptionStore(tokenStore),
    analyticalStore: new AnalyticalStore(tokenStore)
};

export default rootStore;
import React, { useEffect } from "react";
import { Row, Col, Divider, Spin, Typography } from 'antd';
import { CopyFilled, CopyOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { Observer } from 'mobx-react-lite';
import { useStore } from "../../use-stores";
import { PageLayout } from "../../layouts/page/page";
import { toJS } from "mobx";
import { NotifyDark } from "../../utils/snippet";
import moment from 'moment';

const AccountPage: React.FC = () => {
    /*********************************************************************INITIAT PAGE */
    const { vendorStore, tokenStore } = useStore();
    const user: any = tokenStore.getUser();

    //console.log(moment.duration('250', 'days').months(), moment.duration('250', 'days').days())
    useEffect(() => {
        if (user && !vendorStore.accountInfo && !toJS(vendorStore.accountInfo)) {
            vendorStore.getAccountInfo(user.id).then((_d: any) => {
                console.log(toJS(vendorStore.accountInfo))
                if (_d && _d.status === "error") {
                    NotifyDark(_d.status, "Failed", _d.message);
                }
            })
        }
    }, [])

    return <Observer>{() => {
        return (
            <PageLayout title="API Integration details">
                {toJS(vendorStore.accountInfo) ? (
                    <>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                                <div className="app-container">

                                    <Row gutter={[12, 12]}><Col span={24}><h2 className='app-h2-header'>API Integration details</h2></Col></Row>
                                    <Divider />
                                    <Row gutter={[16, 16]} className="pd-bt-2">
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                            <label className="app-custom-label">Client-ID</label>
                                        </Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                                            <Typography.Paragraph copyable={{ text: `${vendorStore.accountInfo.vendor.id}`, icon: [<CopyOutlined key="copy-icon" />, <CopyFilled key="copied-icon" />], tooltips: ["Copy", "Copied!!"] }}>
                                                : {vendorStore.accountInfo.vendor.id}
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className="pd-bt-2">
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                            <label className="app-custom-label">Client-Secret</label>
                                        </Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                                            <Typography.Paragraph copyable={{ text: `${vendorStore.accountInfo.vendor.secret}`, icon: [<CopyOutlined key="copy-icon" />, <CopyFilled key="copied-icon" />], tooltips: ["Copy", "Copied!!"] }}>
                                                : ******************************
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className="pd-bt-2">
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                                            <label className="app-custom-label">API-Document</label>
                                        </Col>
                                        <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
                                            <Typography.Paragraph>
                                                : <a href="#" target={'_blank'}>Shiphyper-API-Doc.docx <CloudDownloadOutlined /></a>
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <div className="app-container">
                                    <Row gutter={[12, 12]}><Col span={24}><h2 className='app-h2-header'>Plan Details</h2></Col></Row>
                                    <Divider />
                                    <Row gutter={[16, 16]} className="pd-bt-2">
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                            <label className="app-custom-label">Plan Name</label>
                                        </Col>
                                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                                            <Typography.Paragraph>
                                                : Free
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className="pd-bt-2">
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                            <label className="app-custom-label">Plan Validity</label>
                                        </Col>
                                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                                            <Typography.Paragraph>
                                                : 180 Days
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className="pd-bt-2">
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                            <label className="app-custom-label">Subscribed On</label>
                                        </Col>
                                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                                            <Typography.Paragraph>
                                                : {moment(vendorStore.accountInfo.vendor.created_at).format('DD-MMM-YYYY')}
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} className="pd-bt-2">
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                            <label className="app-custom-label">Description</label>
                                        </Col>
                                        <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
                                            <Typography.Paragraph>
                                                : This is free plan for 6 months from registration.
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <div style={{
                        height: '90vh !important',
                        textAlign: 'center',
                        borderRadius: '4px'
                    }}>
                        <Spin spinning={true} tip="Loading..." />
                    </div>
                )}
            </PageLayout>
        )
    }}</Observer>
}

export default AccountPage;
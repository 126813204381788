export const fetchResidence = `query Residences {
          countries {
            value: comments
            label: comments
            children: states {
              value: name
              label: name
              children: cities {
                value: name
                label: name
              } 
            }
          }
        }`;

export const fetchServiceProviders = `query fetchServiceProviders($limit: Int,$offset: Int) {
  delivery_service_providers(limit: $limit, where: {active: {_eq: true}}, order_by: {name: asc}, offset: $offset) {
    logo_url
    id
    label
    name
  }
}`;
export const fetchSubscriptions = `query fetchSubscriptions($limit: Int, $offset: Int, $vendor_id: uuid) {
  subscribed_services(where: {vendor_id: {_eq: $vendor_id}, active: {_eq: true}}, limit: $limit, offset: $offset) {
    delivery_service_provider_id
    id
    use_vendor_credential
  }
}`;
export const fetchSubscriptionCount = `query fetchSubscriptionCount($vendor_id: uuid) {
  subscribed_services_aggregate(where: {vendor_id:{_eq: $vendor_id}}) {
    subscriptions: aggregate {
      count
    }
  }
}`;
export const fetchSubscriptionsWithProvider = `query fetchSubscriptions($limit: Int, $offset: Int, $vendor_id: uuid) {
  subscribed_services(where: {vendor_id: {_eq: $vendor_id}, active: {_eq: true}}, limit: $limit, offset: $offset) {
    delivery_service_provider_id
    id
    use_vendor_credential
    service_provider {
      logo_url
      id
      name
      label
    }
  }
}`;
export const fetchOrders = `query fetchOrders($limit: Int, $offset: Int, $vendor_id: uuid) {
  delivery_requests(limit: $limit, offset: $offset, order_by: {created_at: desc}, where: {vendor_id: {_eq: $vendor_id}}) {
    id
    req_no
    status
    created_at
    service_provider
    external_track_id
    origin
    destination
    iteminfo
  }
}`;
export const fetchAccountInfo = `query getMyAccount($user_id: uuid) {
  vendor_users(where: {id: {_eq: $user_id}, active: {_eq: true}}) {
    country_code
    active
    email
    email_verified
    firstname
    id
    lastname
    mobile
    mobile_verified
    owner
    password
    role
    vendor {
      active
      address_line1
      address_line2
      city
      companyname
      country
      id
      logo_url
      pincode
      state
      website
      secret
      created_at
    }
    vendor_id
  }
}`;
export const setAccountInfo = `mutation MyMutation ($vendor_id: uuid!, $vendor_user_id: uuid!, $user: vendor_users_set_input, $vendor: vendors_set_input){
  update_vendors_by_pk(_set: $vendor, pk_columns: {id: $vendor_id}) {
    id
  }
  update_vendor_users_by_pk(_set: $user, pk_columns: {id: $vendor_user_id}) {
    id
  }
}`;
export const fetchAnalytics = `query fetchAnalytics {
  delivery_requests(where: {vendor_id: {_eq: "3fd252d6-f460-4706-b0fd-c02e9c4a3b03"}}) {
    id
    service_provider
    created_at
  }
}`;
export const barChartQry = `query barChart($vendor_id: uuid) {
  delivery_requests_aggregate(where: {vendor_id: {_eq: $vendor_id}}) {
    aggregate {
      count
    }
  }
  cancelled: delivery_requests_aggregate(where: {status: {_eq: "cancelled"}, vendor_id: {_eq: $vendor_id}}) {
    aggregate {
      count
    }
  }
  completed: delivery_requests_aggregate(where: {status: {_eq: "completed"}, vendor_id: {_eq: $vendor_id}}) {
    aggregate {
      count
    }
  }
  rejected: delivery_requests_aggregate(where: {status: {_eq: "rejected"}, vendor_id: {_eq: $vendor_id}}) {
    aggregate {
      count
    }
  }
  outForDelivery: delivery_requests_aggregate(where: {status: {_eq: "out-for-delivery"}, vendor_id: {_eq: $vendor_id}}) {
    aggregate {
      count
    }
  }
  failed: delivery_requests_aggregate(where: {status: {_eq: "failed"}, vendor_id: {_eq: $vendor_id}}) {
    aggregate {
      count
    }
  }
}`;
export const lineChartQry = `query MyQuery($vendor_id: uuid, $timezone: String) {
  monthly_graph_function(args: {vendorid: $vendor_id, timezone: $timezone}, order_by: {created_at: asc}) {
    service_provider
    created_at
    amount
  }
}`;
export const dailyChartQry = `query DailyGraph($vendor_id: uuid, $timezone: String) {
  daily_graph_function(args: {vendorid: $vendor_id, timezone: $timezone}, order_by: {created_at: asc}) {
    amount
    count
    created_at
  }
}`;



/*
query MyQuery($vendor_id: uuid, $timezone: String) {
  monthly_graph_function(args: {vendor_id: $vendor_id, timezone: $timezone}) {
    service_provider
    created_at
    amount
  }
}
VARIABLES
{
  "vendor_id": "56abe6c6-b80c-42ef-b016-b532310952be",
  "timezone": "US/Pacific"
}
*/
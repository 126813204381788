import React, { useEffect, useState } from "react";
import { Row, Col, Space, List, Skeleton, Button, Modal, Form, Input } from 'antd';
import { Observer } from 'mobx-react-lite';
import { useStore } from "../../use-stores";
import { PageLayout } from "../../layouts/page/page";
import { toJS } from "mobx";
import { OrderTimeLine } from "./components/timeline";
import { DeliveryOrderWidget } from "./components/order-widget";
import { Notify, NotifyDark } from "../../utils/snippet";

const ListOrderPage: React.FC = () => {
    const { orderStore, tokenStore, generalStore } = useStore();
    const user: any = tokenStore.getUser();
    // const [loading, setLoading] = useState(false); // this should be used instead of store level boolean
    useEffect(() => {
        if (user && !orderStore.isOrderLoading && (!toJS(orderStore.allOrders) || toJS(orderStore.allOrders).length === 0)) {
            // setLoading(true);
            orderStore.getOrders(user.vendor_id).then(_d => {
                if (_d && _d.status === 'error') NotifyDark(_d.status, "Failed", _d.message);
            }).finally(() => {
                // setLoading(false)
            })
        }
    }, []);
    /********************************************************************Cancel Order */
    const [disableCancel, setDisableCancel] = useState(false);
    const [cancelReasonModal, setCancelReasonModal] = useState(false);
    const [cancelOrderInfo, setCancelOrderInfo] = useState<any>(null);
    const showCancelModal = (id: string, index: number) => {
        setCancelOrderInfo({ id: id, index: index });
        setCancelReasonModal(true);
    }
    const cancelOrder = async (values: any) => {
        setDisableCancel(true);
        let _post = {
            req_id: cancelOrderInfo.id,
            reason: values.reason
        }
        await orderStore.doCancel(_post).then((_d: any) => {
            if (_d && _d.status === 'error') NotifyDark(_d.status, "Failed", _d.message);
            else Notify(_d.status, "Success", 'Order Successfully Cancelled');
        })
        orderStore.setAllOrders('', 'update', cancelOrderInfo.index)
        setDisableCancel(false);
        setCancelReasonModal(false);
    }
    const onFailure = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    /********************************************************************Retry Order */
    const onRetry = async (order_id: string) => {
        await orderStore.doRetry({ order_id: order_id, vendor_id: user.vendor_id }).then((_d: any) => {
            if (_d && _d.status === 'error') NotifyDark(_d.status, "Failed", _d.message);
            else Notify(_d.status, "Success", 'Order Successfully Cancelled');
        }).finally(() => {
            return true
        })
    }
    /********************************************************************Tracking Info */
    const [trackingInfoModal, setTrackingInfoModal] = useState(false);
    const [currentTrackItemId, setCurrentTrackItemId] = useState<number>(-1);
    // const [currentTrackItem, setCurrentTrackItem] = useState<any>();
    /********************************************************************Load More */
    const [orderLoadMore, setOrderLoadMore] = useState<number>(0);
    const onLoadMore = async () => {
        orderStore.getOrders(user.vendor_id, 5, orderLoadMore + 5).then((_d: any) => {
            if (_d && _d.status === 'error') NotifyDark(_d.status, "Failed", _d.message);
        })
        setOrderLoadMore(orderLoadMore + 5)
        window.dispatchEvent(new Event('resize'));
    };
    /********************************************************************Common */
    const handleCancel = () => {
        setCancelReasonModal(false);
        setTrackingInfoModal(false);
    };
    const onSelection = (item: any) => {
        // setCurrentTrackItem(item)
        setCurrentTrackItemId(item.id);
        setTrackingInfoModal(true);
    }
    return <Observer>{() => {
        return (
            <PageLayout title="Order History">
                <List style={{ minHeight: '350px' }} loading={orderStore.isOrderLoading} dataSource={toJS(orderStore.allOrders)}
                    loadMore={!orderStore.isOrderLoading && toJS(orderStore.allOrders).length >= 2 ? <div style={{ textAlign: 'center', marginTop: 12, height: 32, lineHeight: '32px', }}><Button className="app-custom-btn-outline" onClick={(() => { onLoadMore() })}>loading more</Button></div> : <></>}
                    renderItem={(item: any, index: number) => (
                        <div>
                            <Skeleton avatar loading={orderStore.isOrderLoading} active>
                                <DeliveryOrderWidget item={item} onSelection={onSelection} onCancelRequest={() => showCancelModal(item.id, index)} onRetry={onRetry} />
                            </Skeleton>
                        </div>
                    )} />
                <Modal visible={cancelReasonModal} onCancel={handleCancel} footer={null}>
                    <Form name="basic" layout="vertical" labelCol={{ span: 24 }} labelAlign="left" wrapperCol={{ span: 24 }} initialValues={{ remember: true }} onFinish={cancelOrder} onFinishFailed={onFailure} disabled={disableCancel} autoComplete="off">
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Row justify="center"><Col>Why are you cancelling this order</Col></Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="reason" name="reason" rules={[{ required: true, message: 'Please input the reason!' }]} >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col>
                                    <Space size={"large"}>
                                        <Button htmlType="submit" type="primary">Submit</Button>
                                        <Button onClick={handleCancel}>Cancel</Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Form>
                </Modal>
                <Modal visible={trackingInfoModal} onCancel={handleCancel} footer={null}>
                    <OrderTimeLine req_no={currentTrackItemId} />
                </Modal>
            </PageLayout>
        )
    }}</Observer>
}

export default ListOrderPage;
import React, { useState } from "react";
import { Menu } from 'antd';
import { Observer } from 'mobx-react';
import {
    MenuOutlined, AppstoreOutlined, QuestionCircleOutlined,
    StockOutlined, HistoryOutlined, LogoutOutlined,
    SettingOutlined, PlusCircleOutlined, ApiOutlined
} from "@ant-design/icons";
import { useStore } from "../../use-stores";
import { useNavigate } from "react-router-dom";
import './sidemenu.css';

function getItem(label: string, key: string | number, icon?: any, children?: any, type?: any) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

export const SideMenu: React.FC = () => {
    console.log('inside re render of sidemenu');
    const { tokenStore, generalStore } = useStore();
    const navigate = useNavigate();
    const [items, setMenuItems] = useState([getItem('Menu', 'menu', <AppstoreOutlined />)]);
    if (tokenStore.validToken()) {
        console.log('inside menu construction');
        if (items.length !== 9) {
            setMenuItems(items => [
                getItem('Menu', 'menu', <MenuOutlined />),
                getItem('Dashboard', 'dashboard', <AppstoreOutlined />),
                getItem('Order History', 'orders', <HistoryOutlined />),
                getItem('Place An Order', 'createorder', <PlusCircleOutlined />),
                getItem('Subscription', 'subscription', <ApiOutlined />),
                getItem('Analytics', 'analytics', <StockOutlined />),
                getItem('Settings', 'settings', <SettingOutlined />),
                getItem('F.A.Q', 'faq', <QuestionCircleOutlined />),
                getItem('Logout', 'logout', <LogoutOutlined />)
            ]);
        }
    }
    const onMenuItemSelection = (menuprops: any): void => {
        const { key } = menuprops;
        console.log(key)
        if (key === 'menu') {
            generalStore.toggleMenuVisibility();
        } else if (key === 'logout') {
            tokenStore.logout();
            generalStore.setSelectedMenuItem();
            navigate('/login');
        } else {
            if (key === generalStore.selectedMenu) return;
            generalStore.setSelectedMenuItem(key);
            if (key === 'dashboard') {
                console.log('selected menu item is dashboard')
                navigate('/dashboard');
            } else if (key === 'orders') {
                console.log('selected menu item is orders')
                navigate('/orders');
            } else if (key === 'createorder') {
                console.log('selected menu item is createorder')
                navigate('/createorder');
            } else if (key === 'subscription') {
                console.log('selected menu item is subscription')
                navigate('/providers');
            } else if (key === 'analytics') {
                console.log('selected menu item is analytics')
                navigate('/analytics');
            } else if (key === 'settings') {
                console.log('selected menu item is settings')
                navigate('/profile');
            } else if (key === 'faq') {
                console.log('selected menu item is faq')
                navigate('/faq');
            }
        }
    }

    return <Observer>{() => {
        return (
            <Menu className="Admin-menu" style={{ minHeight: "92.5vh", height: "100%", background: "var(--Adminprimary)" }}
                defaultSelectedKeys={['menu', 'dashboard']}
                mode="inline"
                theme="dark"
                selectedKeys={[generalStore.selectedMenu || "dashboard"]}
                inlineCollapsed={!generalStore.isMenuOpen}
                items={items}
                onClick={onMenuItemSelection}
            />
        )
    }}</Observer>
}
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Modal, Button, Image, Carousel } from 'antd';
import { AppstoreAddOutlined, PlusCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { useStore } from "../../../use-stores";
import { Observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Dashboard from "src/assets/dashboard.svg";

const contentStyle: React.CSSProperties = {
    margin: 'auto'
};

export const CardsSection: React.FC = () => {
    const { subscriptionStore, tokenStore } = useStore();
    const navigate = useNavigate()
    const user = tokenStore.getUser();
    const [subCount, setSubCount] = useState<number>(0);
    const [notificationModalOpen, setNotificationModalOpen] = useState<boolean>(false)
    useEffect(() => {
        if (user) {
            console.log('getting subscription in card section');
            subscriptionStore.getSubscriptionCount(user.vendor_id).then((r: any) => {
                if (r) { setSubCount(r); }
                if (r == 0) { setNotificationModalOpen(true) }
            });
        }
    }, [])
    function createShipping(element: any) {
        navigate('/createorder');
    }
    function showOrderHistory(element: any) {
        navigate('/orders');
    }
    const handleCancel = () => {
        setNotificationModalOpen(false);
    };
    /*************************************************************************COMMON */
    const [slide, setSlide] = useState<any>(0);
    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
    };

    return <Observer>{() => {
        return (
            <div className="app-container">
                <Row gutter={[12, 12]}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Card>
                            <Statistic
                                title="Total Subscriptions" value={subCount}
                                precision={0} valueStyle={{ color: 'var(--Adminprimary)', fontFamily: "Kanit, sans-serif" }}
                                prefix={<AppstoreAddOutlined />} suffix=""
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Card onClick={createShipping}>
                            <Statistic
                                title="Shipping Orders" value={'Add'}
                                precision={2} valueStyle={{ color: 'var(--Adminprimary)', fontFamily: "Kanit, sans-serif" }}
                                prefix={<PlusCircleOutlined />} suffix=""
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <Card onClick={showOrderHistory}>
                            <Statistic
                                title="Shipment History" value={'Check History'}
                                precision={2} valueStyle={{ color: 'var(--Adminprimary)', fontFamily: "Kanit, sans-serif" }}
                                prefix={<HistoryOutlined />} suffix=""
                            />
                        </Card>
                    </Col>
                </Row>
                <Modal className="question-modal dashbrdpg-carousel" visible={notificationModalOpen} onCancel={handleCancel} centered footer={null} width={800}>
                    <Carousel afterChange={onChange} ref={ref => { ref?.goTo(slide, true) }} >
                        <div style={contentStyle}>
                            <Row>
                                <Col span={12}><Image width={'100%'} src={Dashboard} preview={false} /></Col>
                                <Col span={12}>
                                    <h2 className="app-h2-header">Subscrib Now</h2>
                                    <p>Subscribe the service providers first...! Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <Button onClick={() => { setSlide(1) }} className="app-primary-btn" >Next</Button>
                                </Col>
                            </Row>
                        </div>
                        <div style={contentStyle}>
                            <Row>
                                <Col span={12}><Image width={'100%'} src={Dashboard} preview={false} /></Col>
                                <Col span={12}>
                                    <h2 className="app-h2-header">Subscrib Now</h2>
                                    <p>Subscribe the service providers first...! Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <Button onClick={() => { setSlide(2) }} className="app-primary-btn" >Next</Button>
                                </Col>
                            </Row>
                        </div>
                        <div style={contentStyle}>
                            <Row>
                                <Col span={12}><Image width={'100%'} src={Dashboard} preview={false} /></Col>
                                <Col span={12}>
                                    <h2 className="app-h2-header">Subscrib Now</h2>
                                    <p>Subscribe the service providers first...! Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                                    <Button onClick={() => { setSlide(0) }} className="app-primary-btn" >Back</Button>
                                </Col>
                            </Row>
                        </div>
                    </Carousel>
                </Modal>
            </div>
        )
    }}</Observer>
}
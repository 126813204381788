import React from 'react';
import { Tabs, Row, Col, Space, Collapse } from 'antd';
import { Observer } from 'mobx-react-lite';
import { CaretRightOutlined } from '@ant-design/icons';
import { PageLayout } from '../../layouts/page/page';
import './faq.css';

const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const FaqPage: React.FC = () => {
    return <Observer>{() => {
        return (
            <PageLayout title="F.A.Q">
                <div className="app-container">
                    <Row justify="start" className='faqpg-box'>
                        <Col span={24}>
                            <Tabs type="card" defaultActiveKey="1">
                                {/***************************************************************************************/}
                                <Tabs.TabPane tab="General" key="1">
                                    <Collapse bordered={false} defaultActiveKey={['1']} className="site-collapse-custom-collapse"
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel header="Why shiphyper?" key="1" className="site-collapse-custom-panel">
                                            <p>The Shiphyper is an intermediator between the Delivery service provider and Users. It is a centralized application for delivery partners. Here you can choose different delivery partners for your courier. And you can Integrate different delivery partners into your application using this single integration.</p>
                                        </Panel>
                                        <Panel header="What are the features Shiphyper providing?" key="2" className="site-collapse-custom-panel">
                                            <p>1. You can integrate multiple delivery partners in a single account
                                                2. You can schedule the courier delivery.
                                                3. And you can get quotations, Cancel, or Retry and Track the orders
                                                4. You can check the different delivery partner's orders in a single account.
                                                5. We are providing API services as well.
                                            </p>
                                        </Panel>
                                        <Panel header="How can I find the service availability at my locations?" key="3" className="site-collapse-custom-panel">
                                            <p>You can find those details on the following link. Then enter the City name or Pincode of your location. Then It will show you the available delivery partner list of your location. </p>
                                        </Panel>
                                        <Panel header="Where can I change my password?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="How can I change my Email-Id (or) Phone no?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="How to contact Shiphyper customer service?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="Where can I read the Terms and Conditions?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="What are the plans available in Shiphypers?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                    </Collapse>
                                </Tabs.TabPane>
                                {/***************************************************************************************/}
                                <Tabs.TabPane tab="Service Providers" key="2">
                                    <Collapse bordered={false} defaultActiveKey={['1']} className="site-collapse-custom-collapse"
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel header="Is Shiphyper providing API services?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="Who is Service providers?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="How to subscribe the service providers?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                    </Collapse>
                                </Tabs.TabPane>
                                {/***************************************************************************************/}
                                <Tabs.TabPane tab="Orders" key="3">
                                <Collapse bordered={false} defaultActiveKey={['1']} className="site-collapse-custom-collapse"
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel header="How to create orders?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="How can I track the Orders?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="How to cancel the orders?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="What is failed Orders?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="Is it posible to retry the failed orders and how?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                    </Collapse>
                                </Tabs.TabPane>
                                {/***************************************************************************************/}
                                <Tabs.TabPane tab="API" key="4">
                                <Collapse bordered={false} defaultActiveKey={['1']} className="site-collapse-custom-collapse"
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                                        <Panel header="Is Shiphyper providing API services?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="How to download API Document?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="Where can I get the Integration details?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                        <Panel header="Where should I update the callback URL?" key="3" className="site-collapse-custom-panel">
                                            <p>{text}</p>
                                        </Panel>
                                    </Collapse>
                                </Tabs.TabPane>
                                {/***************************************************************************************/}
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </PageLayout>
        )
    }}</Observer>
}

export default FaqPage;
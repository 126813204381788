import { CopyFilled, CopyOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Typography } from "antd";
import { Observer } from "mobx-react";

export const PorterCredentialForm: React.FC<{ providername: string, clientID: string }> = ({ providername, clientID }) => {
    return (
        <Observer>
            {() => {
                return providername === "porter" ? (
                    <div>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label={<label className="web-custom-label">Client Secret</label>}
                                    name="client_secret"
                                    rules={[{ required: true, message: "Please input your client secret!" }]}
                                >
                                    <Input.Password className="app-primary-form-input" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Typography.Text strong>Note:</Typography.Text>
                                <Typography.Text>
                                    {" "}
                                    Please make sure, you mention/configure below url as callback url in your Porter Account
                                </Typography.Text>
                                <Typography.Paragraph
                                    code
                                    copyable={{
                                        icon: [<CopyOutlined key="copy-icon" />, <CopyFilled key="copied-icon" />],
                                        tooltips: ["Copy", "Copied!!"],
                                    }}
                                    style={{ paddingTop: "5px" }}
                                >
                                    https://shiphyper-server-dev.herokuapp.com/api/webhooks/porter/update
                                </Typography.Paragraph>
                                <Typography.Text>
                                    {" "}
                                    Header Key and Value is below here. And the Http method is <b>GET</b>
                                </Typography.Text>
                                <Typography.Paragraph
                                    code
                                    copyable={{
                                        icon: [<CopyOutlined key="copy-icon" />, <CopyFilled key="copied-icon" />],
                                        tooltips: ["Copy", "Copied!!"],
                                    }}
                                    style={{ paddingTop: "5px" }}
                                >
                                    Client-id: {clientID}
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <></>
                );
            }}
        </Observer>
    );
};

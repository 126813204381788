import React from "react";
import { Observer } from "mobx-react-lite";
import { Line } from '@ant-design/plots';
import { Empty, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const MonthlyReportAnalytics: React.FC<{ data: any[] }> = ({ data }) => {
    /********************************************************************Initiating Page */
    const config: any = {
        data,
        xField: 'created_at',
        yField: 'amount',
        seriesField: 'service_provider',
        yAxis: {
            label: {
                formatter: (v: any) => `${(v / 5).toFixed(1)}`,
            },
        },
        legend: {
            position: 'top',
        },
        smooth: true,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    };

    return (
        <Observer>
            {() => {
                return (
                    <div className="app-container">
                        <h2 className="analyticpg-bar-head app-h2-header">Monthly Report Based on Service Provider
                            <span className='analyticpg-tooltip'><Tooltip title="Monthly Report Based on Service Provider. And the x-Field is mention year and month, y-Field is mention amount"> <ExclamationCircleOutlined /></Tooltip></span>
                        </h2>
                        {data && data.length > 0 ? (<Line {...config} />) : (<Empty />)}
                    </div>
                );
            }}
        </Observer>
    );
};

import { TokenStore } from './tokenstore';
import { observable, action, makeObservable } from 'mobx';
import axios, { AxiosRequestHeaders } from 'axios';
import { GRAPHQL_URL, SERVICE_URL } from '../utils/constants';
import { fetchServiceProviders } from '../utils/queries';
import { isNotEmpty } from '../utils';

const _ErrorResponse = (_m: any = null, _d: any = null) => {
    return { status: 'error', message: _m && typeof _m === 'string' ? _m : 'Something went wrong, Please try again after sometime.', data: _d }
};
const _SuccessResponse = (_m: any = null, _d: any = null) => {
    return { status: 'success', message: _m && typeof _m === 'string' ? _m : 'Process Successfully Done', data: _d }
};

export class ServiceProviderStore {
    /*************************************************PROVIDERS LIST */
    providers: any[] = [];
    isFetching: boolean = false;
    /*************************************************SUGGEST QUOTE */
    suggestQuote: any[] = [];

    constructor(private tokenStore: TokenStore) {
        makeObservable(this, {
            providers: observable,
            isFetching: observable,
            suggestQuote: observable,
            setIsFetching: action,
            setProviders: action,
            setSuggestQuote: action
        });
    }
    /***********************************************************************FUNCTIONS */
    private getHeaders(): AxiosRequestHeaders {
        if (this.tokenStore.validToken()) {
            return { 'authorization': `Bearer ${this.tokenStore.getToken()}`, 'x-clientapp': 'portalweb' }
        }
        return { 'x-hasura-role': 'anonymous', 'x-clientapp': 'portalweb' }
    }
    async getProviders(limit = 100, skip = 0) {
        const _self = this;
        try {
            let result = null;
            _self.setIsFetching(true);
            let res = await axios.post(GRAPHQL_URL, {
                query: fetchServiceProviders, variables: { "limit": limit, "offset": skip }
            }, { headers: _self.getHeaders() })
            _self.setIsFetching(false);
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message)
            }
            if (res && res.data) {
                result = res.data.data.delivery_service_providers;
                _self.setProviders(res.data.data.delivery_service_providers);
                return _SuccessResponse(null, res.data);
            }
            return _ErrorResponse()
        } catch (e) {
            console.error(1, e);
            _self.setIsFetching(false);
            _ErrorResponse(e)
        }
    }
    public async getSuggestQuote(_post: any) {
        const _self = this;
        try {
            _post.quote_type = 'suggestion';
            let res: any = await axios.post(`${SERVICE_URL}/api/delivery/quote`, _post, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (res.status && res.status === 'failed') return _ErrorResponse(res?.data?.message || null)
                _self.setSuggestQuote(res.data.data)
                return { status: 'success', data: res.data.data }
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }
    /***********************************************************************ACTIONS */
    setProviders(providers: any) {
        this.providers = providers;
    }
    setIsFetching(v: boolean) {
        this.isFetching = v;
    }
    setSuggestQuote(v: any) {
        this.suggestQuote = v;
    }
}
import React, { useEffect, useState } from "react";
import { Row, Col, Badge, Button, Spin } from "antd";
import { Observer } from "mobx-react";
import { isNotBlank } from "src/utils";
import { statusColor, canCancel, canRetry, providerStyle } from "src/utils/snippet";
import "./../listorder.css";

export const DeliveryOrderWidget: React.FC<any> = ({ item, onSelection, onCancelRequest, onRetry }) => {
    const [disableCancel, setDisableCancel] = useState(false);
    const [disableRetry, setDisableRetry] = useState(false);
    const [pageLoader, setPageLoader] = useState(false)

    useEffect(() => {
        setDisableCancel(!canCancel(item.status));
        setDisableRetry(!canRetry(item.status));
    }, [item.status]);

    const letsRetry = async (item: any) => {
        setPageLoader(true);
        await onRetry(item.id).finally(() => {
            setPageLoader(false);
        })
    }

    return <Observer>{() => {
        return (
            <div className="app-container">
                <Spin spinning={pageLoader} tip="Processing...">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={4} className='listpg-order-item-1'>
                            <div className="listpg-badge-box">
                                <div className="listpg-orderid">Order #{item.req_no} <span>
                                    <Badge className="site-badge-count-109 firstCap" style={providerStyle(item.service_provider)} count={item.service_provider} />
                                </span></div>
                                <div><Badge className="site-badge-count-109 firstCap" count={item.status} style={{ backgroundColor: statusColor(item.status) }} /></div>
                            </div>
                            <span><a href="#" onClick={() => onSelection(item)} className="listpg-track">Track Now</a></span>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={8} className='listpg-order-item-1'>
                            <div className="listpg-address-container">
                                <h5>From:</h5>
                                <p className="listpg-address-box">{item.origin.name}, {item.origin.street_address_1}, {item.origin.street_address_2}, {item.destination.city}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={8} className='listpg-order-item-1'>
                            <div className="listpg-address-container">
                                <h5>To:</h5>
                                <p className="listpg-address-box">{item.destination.name}, {item.destination.street_address_1}, {item.destination.street_address_2}, {item.destination.city}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={4} className='listpg-order-item-2'>
                            {!disableCancel ? <Button onClick={(event) => { onCancelRequest(item); }} className="app-danger-btn app-btn-alter">Cancel Order</Button> :
                                !disableRetry ? <Button onClick={(event) => { letsRetry(item) }} className="app-retry-btn app-btn-alter">Retry</Button> : <Button disabled className="app-danger-btn-disable app-btn-alter">Cancel Order</Button>}
                        </Col>
                    </Row>
                    <Row className="listpg-iteminfo">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={4}>
                            <div style={{ display: 'flex' }}>
                                <h5>Weight: <span className="listpg-item-box">{item.iteminfo.weight + ' ' + item.iteminfo.weightunit}</span></h5>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={8}>
                            <div style={{ display: 'flex' }}>
                                <h5>Item Type: <span className="listpg-item-box">{item.iteminfo.itemtype}</span></h5>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={8}>
                            <div style={{ display: 'flex' }}>
                                <h5>Height: <span className="listpg-item-box">{item.iteminfo.height + ' ' + item.iteminfo.heightunit}</span></h5>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={4}>
                            <div style={{ display: 'flex' }}>
                                <h5>Width: <span className="listpg-item-box">{item.iteminfo.width + ' ' + item.iteminfo.widthunit}</span></h5>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <p>{isNotBlank(item.notes) && <Col xl={24}>Extra Notes: {item.notes}</Col>}</p>
                    </Row>
                </Spin>
            </div>
        )
    }}</Observer>
}
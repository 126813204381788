import { DualAxes } from '@ant-design/plots';
import { Empty, Tooltip } from 'antd';
import { ExclamationCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';

export const ChartSections: React.FC<{ data: any[] }> = ({ data }) => {
    const config = {
        data: [data, data],
        xField: 'created_at',
        yField: ['count', 'amount'],
        geometryOptions: [
            {
                geometry: 'line',
                smooth: false,
                color: '#5B8FF9',
                label: {
                    formatter: (datum: any) => {
                        return `${datum.amount}个`;
                    },
                },
                lineStyle: {
                    lineWidth: 3,
                    lineDash: [5, 5],
                },
            },
            {
                geometry: 'line',
                smooth: true,
                color: '#5AD8A6',
                lineStyle: {
                    lineWidth: 4,
                    opacity: 0.5,
                },
                label: {
                    formatter: (datum: any) => {
                        return `${datum.count}个`;
                    },
                },
                point: {
                    shape: 'circle',
                    size: 4,
                    style: {
                        opacity: 0.5,
                        stroke: '#5AD8A6',
                        fill: '#fff',
                    },
                },
            },
        ],
    };
    return (
        <div className="app-container">
            <div className='row-box'>
                <div className='col-50'><h2 className="app-h2-header">Date Based Report <span className='dashbrdpg-tooltip'><Tooltip title="The chart help to get daily order details for your business"> <ExclamationCircleOutlined /></Tooltip></span></h2></div>
                <div className='col-50-end'><a href="/analytics" className='dashbrdpg-a-tag'>View More <ArrowRightOutlined /></a></div>
            </div>
            {data && data.length > 0 ? (<DualAxes {...config} />) : (<Empty />)}
        </div>
    );
};
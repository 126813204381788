import React, { useState } from 'react';
import { Row, Form, Col, Divider, Button, DatePicker, Image } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { useStore } from "../../../use-stores";
import { Observer } from 'mobx-react';

const disabledDate: RangePickerProps['disabledDate'] = current => {
    //return current && current < moment().endOf('day');
    return current && current.valueOf() < Date.now();
};

export const SelectTimeForm: React.FC<any> = ({ order, onCompletion, onRevert }) => {
    const { orderStore } = useStore();
    const [deliverynow, setDeliverNow] = useState(order.deliverynow || true);
    const onFinish = async (values: any) => {
        orderStore.setIsFormProgress(true);
        let _post = {};
        if (deliverynow) {
            _post = { deliverynow, pickup_datetime: null }
        } else {
            //if (moment(values.pic_datetime._d).format('YYYY-MM-DDTHH:mm:ss') > moment(values.drp_datetime._d).format('YYYY-MM-DDTHH:mm:ss')) { NotifyDark('error', 'Select Drop date after pickup date'); orderStore.setIsFormProgress(false); return false }
            _post = {
                deliverynow,
                pickup_datetime: moment(values.pic_datetime._d).format('YYYY-MM-DDTHH:mm:ss'),
                // drop_datetime: moment(values.drp_datetime._d).format('YYYY-MM-DDTHH:mm:ss')
            }
        }
        // orderStore.setNewOrder({ ...order, _post })
        // orderStore.setCurrentStep(orderStore.currentStep + 1)
        orderStore.setIsFormProgress(false);
        onCompletion({ ...order, ..._post });
    }
    const onFinishFailed = async (errorInfo: any) => {
        console.log('errorInfo: ', errorInfo)
    }

    return <Observer>{() => {
        return (
            <Form name="basic" layout="vertical" labelCol={{ span: 24 }} labelAlign="left" wrapperCol={{ span: 24 }} initialValues={{ remember: true }} disabled={orderStore.isFormProgress} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
                <Divider />
                <Row gutter={[16, 16]}><Col span={12}><h2 className='app-h2-header'>Select Delivery Time</h2></Col></Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <div style={{ height: 'auto', backgroundColor: '#F9FAFF' }}>
                            <Image width={'100%'} height={'487'} src="deliver-now.svg" preview={false} rootClassName={"orderpg-timeImg"} />
                        </div>
                        <br />
                        <Button htmlType='submit' block onClick={() => setDeliverNow(true)} className="app-custom-btn" style={{ margin: 'unset', display: 'revert' }}>Delivery Now</Button>
                    </Col>
                    <Col span={12}>
                        <div style={{ height: 'auto', backgroundColor: '#F9FAFF' }}>
                            <Image width={'100%'} src="deliver-later.svg" preview={false} rootClassName={"orderpg-timeImg"} />
                        </div>
                        <br />
                        <Button block onClick={() => setDeliverNow(false)} className="app-custom-btn-outline" style={{ margin: 'unset', display: 'revert' }}>Schedule Delivery</Button>
                    </Col>
                </Row>
                <Divider />
                {!deliverynow ?
                    <div>
                        <Row gutter={[16, 16]} style={{ textAlign: "center" }}>
                            <Col span={12}>
                                <Form.Item label={<label className="app-custom-label">Pickup Time</label>} name="pic_datetime" rules={[{ required: true, message: 'Please enter the pickup Date and Time' }]} initialValue={order.pickup_datetime}>
                                    <DatePicker style={{ width: '100%' }} className="app-form-input" format="YYYY-MM-DD HH:mm" disabledDate={disabledDate} showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Button htmlType='reset' onClick={onRevert} className="app-custom-btn-outline" style={{ margin: 'unset', display: 'revert' }}>Back</Button>
                            </Col>
                            <Col span={12} style={{ textAlign: "end" }}>
                                <Button htmlType='submit' className="app-custom-btn" style={{ margin: 'unset', display: 'revert' }}>Next</Button>
                            </Col>
                        </Row>
                    </div> : <Row gutter={[16, 16]} style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Button htmlType='reset' onClick={onRevert} className="app-custom-btn-outline" style={{ margin: 'unset', display: 'revert' }}>Back</Button>
                        </Col>
                    </Row>
                }
            </Form>
        )
    }}</Observer>
}
import { Button, Col, Form, Input, Row, List, Skeleton } from "antd";
import React, { useState } from "react";
import Borzo from "src/assets/borzo.svg";
import Dunzo from "src/assets/dunzo.svg";
import Porter from "src/assets/porter.svg";
import { useStore } from "../../../use-stores";
import { NotifyDark } from "../../../utils/snippet";
import "../home.css";
import { toJS } from "mobx";
import { Observer } from 'mobx-react-lite';

export const AvailabilityPage: React.FC = () => {
    const { generalStore } = useStore();
    const [isAvailabilityProcessing, setIsAvailabilityProcessing] = useState<boolean>(false);

    const OnFinish = async (values: any) => {
        setIsAvailabilityProcessing(true);
        await generalStore.getPincodeAvailability(values).then((_d: any) => {
            if (_d && _d.status === 'error') {
                NotifyDark(_d.status, "Failed", _d.message);
                return false
            }
        }).finally(() => {
            setIsAvailabilityProcessing(false);
        })
    };
    const OnFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const getLogo = (provider: String) => {
        switch (provider) {
            case 'dunzo':
                return Dunzo
            case 'borzo':
                return Borzo
            case 'porter':
                return Porter
            default:
                return ''
        }
    }

    return (
        <Observer>
            {() => {
                return (
                    <div className="custom-max-width">
                        <div className="availability-card">
                            <div className="availability-container">
                                <h1 className="mb-3 text-align-center">Check Availability</h1>
                                <p className="mb-4 text-align-center">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                <div className="mb-4">
                                    <Form onFinish={OnFinish} onFinishFailed={OnFinishFailed} autoComplete="off" disabled={isAvailabilityProcessing}>
                                        <Form.Item name="searchContent" rules={[{ required: true, message: "Please enter pincode here!" }]}>
                                            <Input className="web-availability-form-input" placeholder="Postal Code" />
                                        </Form.Item>
                                        <Button htmlType="submit" size="large" className="web-availability-btn">
                                            Check Now
                                        </Button>
                                    </Form>
                                </div>
                                <Row gutter={16}>
                                    <List loading={isAvailabilityProcessing}
                                        grid={{ gutter: 12, column: toJS(generalStore.pincodeAvailability.providers)?.length || 0 }}
                                        locale={{ emptyText: <></> }}
                                        dataSource={toJS(generalStore.pincodeAvailability.providers)}
                                        renderItem={(item: any) => (
                                            <div>
                                                <Skeleton avatar loading={isAvailabilityProcessing} active>
                                                    <Col md={{ span: 6 }}>
                                                        <div className="delivery-partner-img">
                                                            <img src={getLogo(item.name)} alt="delivery-img" />
                                                        </div>
                                                    </Col>
                                                </Skeleton>
                                            </div>
                                        )} />
                                </Row>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Observer>
    )
};

import axios, { AxiosRequestHeaders } from "axios";
import { action, makeObservable, observable } from "mobx";
import Geocode from "react-geocode";
import { IWEBResidence } from "../interfaces/web";
import { GOOGLE_MAP_KEY, GRAPHQL_URL, SERVICE_URL } from "../utils/constants";
import { fetchResidence } from "../utils/queries";
import { TokenStore } from "./tokenstore";
import { isNotEmpty } from '../utils';

const _ErrorResponse = (_m: any = null, _d: any = null) => {
    return { status: 'error', message: typeof _m === 'string' && _m ? _m : 'Something went wrong, Please try again after sometime.', data: _d }
};
const _SuccessResponse = (_m: any = null, _d: any = null) => {
    return { status: 'success', message: _m && typeof _m === 'string' ? _m : 'Process Successfully Done', data: _d }
};

export class GeneralStore {
    /***********************************MENU */
    isNavOpen: boolean = false;
    isMenuOpen: boolean = false;
    selectedMenu: string | null | undefined;
    /***********************************RESIDENCE */
    residence: IWEBResidence | any;
    /***********************************PINCODE AVAILABILITY */
    pincodeAvailability: any = []

    constructor(private tokenStore: TokenStore) {
        makeObservable(this, {
            isNavOpen: observable,
            residence: observable,
            isMenuOpen: observable,
            selectedMenu: observable,
            pincodeAvailability: observable,
            getCountries: action,
            setResidence: action,
            toggleMenuVisibility: action,
            setSelectedMenuItem: action,
            showResponsive: action,
            setPincodeAvailability: action
        });
    }
    /***********************************************************************FUNCTIONS */
    private getHeaders(noToken?: boolean): AxiosRequestHeaders {
        if (noToken) {
            return { "x-hasura-role": "anonymous" };
        } else if (this.tokenStore.validToken()) {
            return { authorization: `Bearer ${this.tokenStore.getToken()}` };
        }
        return { "x-hasura-role": "anonymous" };
    }
    public async getGeoCode(addresses: any) {
        try {
            Geocode.setApiKey(GOOGLE_MAP_KEY);
            let _res: any = [];
            if (addresses && isNotEmpty(addresses)) {
                for (let i = 0; i < addresses.length; i++) {
                    await Geocode.fromAddress(addresses[i]).then((res) => {
                        _res.push(res.results[0].geometry.location);
                    }).catch((err) => { throw new Error(err) });
                }
            }
            return { status: 'success', data: _res }
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    public async sendContactFormMail(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/web/contactmail`, _post, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') return _ErrorResponse(res.data.message || 'Something went wrong, Please try again after sometime.')
                return _SuccessResponse()
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    public async getPincodeAvailability(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/web/getavailablity`, _post, { headers: _self.getHeaders(true) })
            if (res && res.data) {
                if (res.data.status && res.data.status === 'failed') {
                    _self.setPincodeAvailability([]);
                    return _ErrorResponse(res.data.message || 'Something went wrong, Please try again after sometime.')
                } else {
                    _self.setPincodeAvailability(res.data.data);
                    return _SuccessResponse(null, res.data.data)
                }
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e)
            return _ErrorResponse(e)
        }
    }

    /***********************************************************************ACTIONS */
    async getCountries() {
        const _self = this;
        try {
            let result = null;
            let res = await axios.post(GRAPHQL_URL, { query: fetchResidence }, { headers: this.getHeaders() });
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                result = res.data.data.countries;
                _self.setResidence(res.data.data.countries);
                return _SuccessResponse(null, result);
            } else return _ErrorResponse()
        } catch (e: any) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    toggleMenuVisibility = () => {
        console.log("inside toggleMenuVisibility");
        this.isMenuOpen = !this.isMenuOpen;
    };
    setSelectedMenuItem(v?: string) {
        this.selectedMenu = v;
    }
    setResidence(v: any) {
        this.residence = v;
    }
    /*setIsLoading(v: boolean) {
        this.isFetching = v;
    }*/
    // responvive nav bar
    showResponsive = () => {
        this.isNavOpen = !this.isNavOpen;
    };
    setPincodeAvailability(v: any) {
        this.pincodeAvailability = v;
    }
}

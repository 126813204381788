import { Button, Layout } from "antd";
import React from "react";
import "./css/home-footer.css";

const { Footer } = Layout;

const HomeFooter: React.FC = () => {
    return (
        <>
            <Footer style={{ textAlign: "center" }}>
                <div className="custom-max-width">
                    <div className="subscribe-card">
                        <div>
                            <h1>Subscribe Now & Get Special Features!</h1>
                            <h6>Let's subscribe with us and find the fun.</h6>
                        </div>
                        <Button size="large" className="home-custom-btn" style={{ alignSelf: "flex-start", marginTop: "20px" }}>
                            Subscribe
                        </Button>
                    </div>
                </div>
                <p>© 2022 ShipHyper Pvt Ltd. All rights reserved</p>
            </Footer>
        </>
    );
};

export default HomeFooter;

import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useStore } from "../../../use-stores";
import { NotifyDark } from "../../../utils/snippet";
import "../home.css";

const { TextArea } = Input;

export const ContactPage: React.FC = () => {
    const { generalStore } = useStore();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const OnFinish = async (values: any) => {
        setIsProcessing(true);
        await generalStore.sendContactFormMail(values).then((_d: any) => {
            if (_d && _d.status === 'error') {
                NotifyDark(_d.status, "Failed", _d.message);
                return false
            } else {
                NotifyDark(_d.status, "Mail Sent Successfully", _d.message);
            }
        }).finally(() => {
            setIsProcessing(false);
        });
    }
    const OnFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="custom-max-width">
            <h1>Ask Us Anything</h1>
            <p>Send us an email for your any query</p>

            <div className="flex-align-center justify-content-center">
                <div className="contact-form">
                    <Form onFinish={OnFinish} onFinishFailed={OnFinishFailed} autoComplete="off" disabled={isProcessing}>
                        <Form.Item name="sender_email" className="custom-form-control" rules={[{ required: true, type: "email", message: "Please enter your email!" }]}>
                            <Input className="web-contact-form-input" placeholder="Email" />
                        </Form.Item>
                        <Form.Item name="sender_name" className="custom-form-control" rules={[{ required: true, message: "Please enter your Name!" }]}>
                            <Input type="text" className="web-contact-form-input" placeholder="Name" />
                        </Form.Item>
                        <Form.Item name="sender_phone" className="custom-form-control">
                            <Input type="text" className="web-contact-form-input" placeholder="Phone Number" />
                        </Form.Item>
                        <Form.Item name="sender_message" className="custom-form-control" rules={[{ required: true, message: "Please enter the message!" }]}>
                            <TextArea placeholder="Message" className="web-contact-form-textarea" rows={4} style={{ paddingTop: ".5rem" }} />
                        </Form.Item>
                        <Button htmlType="submit" size="large" className="home-custom-btn">
                            Send
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};
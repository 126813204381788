import LoginBg from "src/assets/login-bg.png";
import "./signup.css";
import { SignUpGrid } from "./components/signupform";

const SignupPage = () => {
    return (
        <div className="signup-page">
            <div className="signup-left">
                <img src={LoginBg} alt="login-bg" />
            </div>
            <div className="signup-right">
                <div className="signup-container">
                    <h1>
                        Signup to <br /> <span>SHIPHYER</span>
                    </h1>
                    <SignUpGrid />
                </div>
            </div>
        </div>
    );
};

export default SignupPage;

import { action, observable, makeObservable } from 'mobx';
import { IRegistration } from '../interfaces/services';
import axios, { AxiosRequestHeaders } from 'axios';
import { TokenStore } from './tokenstore';
import { SERVICE_URL, GRAPHQL_URL } from '../utils/constants';
import { fetchAccountInfo, setAccountInfo } from "../utils/queries";
import { isNotEmpty } from '../utils';

const _ErrorResponse = (_m: any = null, _d: any = null) => {
    return { status: 'error', message: _m && typeof _m === 'string' ? _m : 'Something went wrong, Please try again after sometime.', data: _d }
};
const _SuccessResponse = (_m: any = null, _d: any = null) => {
    return { status: 'success', message: _m && typeof _m === 'string' ? _m : 'Process Successfully Done', data: _d }
};

export class VendorStore {
    /***********************************REGISTRATION */
    registrationData: IRegistration | undefined;
    /***********************************ACCOUNT INFO */
    accountInfo: any = null

    constructor(private tokenStore: TokenStore) {
        makeObservable(this, {
            registrationData: observable,
            accountInfo: observable,
            doRegister: action,
            doLogin: action,
            setRegistrationData: action,
            setAccountInfo: action
        })
    }
    /***********************************************************************FUNCTIONS */
    private getHeaders(): AxiosRequestHeaders {
        if (this.tokenStore.validToken()) {
            return { 'authorization': `Bearer ${this.tokenStore.getToken()}`, 'x-clientapp': 'portalweb' }
        }
        return { 'x-hasura-role': 'anonymous', 'x-clientapp': 'portalweb' }
    }
    public async sendOTP(_post: any) {
        const _self = this;
        try {
            let res: any = await axios.post(`${SERVICE_URL}/api/sendotp`, _post, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (!res.data.success) return _ErrorResponse(res.data.message || 'Email/Phone Not Found', { isProcess: false })
                return _SuccessResponse(null, { isProcess: true })
            } else return _ErrorResponse(null, { isProcess: false })
        } catch (e) {
            console.error(e)
            return _ErrorResponse(null, { isProcess: false })
        }
    }
    public async verifyOTP(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/verifyotp`, _post, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (!res.data.success) return _ErrorResponse(res.data.message || 'Email/Phone Not Found')
                _self.tokenStore.setToken(res.data.value, res.data.ttl)
                _self.tokenStore.setUser(res.data.user)
                return _SuccessResponse()
            } else return _ErrorResponse()
        } catch (e: any) {
            console.error(e?.message)
            return _ErrorResponse(e?.message || null)
        }
    }
    public async getAccountInfo(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(GRAPHQL_URL, { query: fetchAccountInfo, variables: { "user_id": _post } }, { headers: _self.getHeaders() });
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                _self.setAccountInfo(res.data.data.vendor_users[0]);
                return _SuccessResponse(null, res.data.data.vendor_users[0]);
            } else return _ErrorResponse()
        } catch (e: any) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    public async changePassword(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/changepassword`, _post, { headers: _self.getHeaders() })
            if (res && res.data) {
                if (!res.data.success) return _ErrorResponse(res.data.message || 'Email/Phone Not Found')
                await _self.getAccountInfo(_post.vendor_user_id)
                return _SuccessResponse(null, res.data.data)
            } else return _ErrorResponse()
        } catch (e: any) {
            console.error(e?.message)
            return _ErrorResponse(e?.message || null)
        }
    }
    public async doSetAccountInfo(_post: any) {
        const _self = this;
        try {
            let res = await axios.post(GRAPHQL_URL, { query: setAccountInfo, variables: _post }, { headers: _self.getHeaders() });
            if (res && res.data && isNotEmpty(res.data.errors)) {
                return _ErrorResponse(res.data.errors[0].message || null)
            }
            if (res && res.data) {
                await _self.getAccountInfo(_post.vendor_user_id)
                return _SuccessResponse();
            } else return _ErrorResponse()
        } catch (e: any) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    /***********************************************************************ACTIONS */
    async doRegister(data: IRegistration) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/vendor/register`, data, { headers: this.getHeaders() })
            if (res && res.data) {
                if (!res.data.success) { return _ErrorResponse(res.data.message || 'Email/Phone already exist'); }
                _self.setRegistrationData(res.data)
                return _SuccessResponse(null, res.data)
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    async doLogin(u: any) {
        const _self = this;
        try {
            let res = await axios.post(`${SERVICE_URL}/api/login`, u, { headers: this.getHeaders() })
            if (res && res.data) {
                if (!res.data.success) { return _ErrorResponse(res.data.message || 'Credentials not matching'); }
                _self.tokenStore.setToken(res.data.value, res.data.ttl)
                _self.tokenStore.setUser(res.data.user)
                return _SuccessResponse()
            } else return _ErrorResponse()
        } catch (e) {
            console.error(e);
            return _ErrorResponse(e)
        }
    }
    setRegistrationData(v: any) {
        this.registrationData = v;
    }
    setAccountInfo(v: any) {
        this.accountInfo = v
    }
}

import { Col, Layout, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import BgRight from "src/assets/main-right.svg";
import VerticalDot from "src/assets/vertical-dot.svg";
import HomeFooter from "src/components/HomeFooter";
import Navbar from "src/components/Navbar";
import { ContactPage } from "./components/contactform";
import { AvailabilityPage } from "./components/availability";

import "./home.css";

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const getToSignUp = () => {
        navigate("/signup");
    };
    return (
        <Layout>
            <Navbar />
            <section id="shHomeMain" className="home-main-section">
                <div className="circle-main"></div>
                <div className="custom-max-width">
                    <Row gutter={16}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} span={12}>
                            <div>
                                <div className="vertical-dot">
                                    <img src={VerticalDot} alt="vertical-dot" />
                                </div>
                                <h5 className="flex-align-center">Faster Safer Easier</h5>
                                <h1>Welcome to ShipHyper</h1>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s,
                                </p>
                                <button onClick={getToSignUp}>Get Started</button>
                                <div className="horizontal-dot">
                                    <img src={VerticalDot} alt="vertical-dot" />
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} span={12}>
                            <div className="" style={{ alignSelf: "flex-start" }}>
                                <img src={BgRight} className="img-fluid" alt="right" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section id="shFeature" className="feature-section">
                <div className="custom-max-width">
                    <h1>Features</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                    <Row gutter={{ xs: 16, sm: 16 }}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                            <div className="feature-card">
                                <h3>1</h3>
                                <h1>Add Multiple</h1>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi
                                    repudiandae
                                </p>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                            <div className="feature-card">
                                <h3>2</h3>
                                <h1>Customise by you</h1>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi
                                    repudiandae
                                </p>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                            <div className="feature-card">
                                <h3>3</h3>
                                <h1>Optimize for search</h1>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi
                                    repudiandae
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section">
                <AvailabilityPage />
            </section>
            <section id="shContactUs" className="contact-section">
                <ContactPage />
            </section>
            <HomeFooter />
        </Layout>
    );
};

export default HomePage;

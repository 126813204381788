import { Button, Form, Input, Row, Col } from 'antd';
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginBg from "src/assets/login-bg.png";
import { useStore } from "../../use-stores";
import { NotifyDark, Notify } from "../../utils/snippet";
import "./login.css";

const LoginPage: React.FC = () => {
    const { tokenStore, vendorStore } = useStore();
    const [loginProcess, setLoginProcess] = useState<boolean>(false)
    const navigate = useNavigate();
    useEffect(() => {
        console.log("inside useeffect of login", tokenStore.validToken());
        if (tokenStore.validToken()) {
            navigate("/dashboard");
        }
    }, [tokenStore.token]);
    const OnFinish = async (values: any) => {
        setLoginProcess(true)
        await vendorStore.doLogin(values).then((_e: any) => {
            setLoginProcess(false)
            if (_e && _e.status === 'error') {
                NotifyDark(_e.status, "Failed", _e.message);
                return false
            }
            Notify(_e.status, "Success", "Login Successfully Completed" || _e.message);
            return true
        });
    };
    const OnFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <div className="web-authform">
            <Row>
                <Col span={12}>
                    <div className="web-authform-left">
                        <img src={LoginBg} alt="login-bg" />
                    </div>
                </Col>
                <Col span={12}>
                    <div className="web-authform-right">
                        <div className="web-authform-container">
                            <h1>
                                Login to <br /> <span>SHIPHYER</span>
                            </h1>
                            <Form disabled={loginProcess} onFinish={OnFinish} onFinishFailed={OnFinishFailed} className="login-form" layout="vertical" autoComplete="off">
                                <Form.Item
                                    label={<label className="web-custom-label">Enter your email address</label>}
                                    name="email"
                                    rules={[{ required: true, message: "Please enter your email!" }]}>
                                    <Input className="web-auth-form-input" />
                                </Form.Item>
                                <Form.Item
                                    label={<label className="web-custom-label">Enter your password</label>}
                                    name="password"
                                    rules={[{ required: true, message: "Please input your password!" }]}>
                                    <Input type="password" className="web-auth-form-input" />
                                </Form.Item>
                                <p className="loginpg-forgotpass-text">
                                    <Link to="/forgotpassword" style={{ color: "var(--Webprimary)" }}>
                                        Forget Password ?
                                    </Link>
                                </p>
                                <Button className="web-primary-button" htmlType="submit">
                                    LOG IN
                                </Button>
                            </Form>
                            <p className="web-authform-newacc-text">
                                Don’t have an account ?{" "}
                                <span>
                                    &nbsp;{" "}
                                    <Link to="/signup" style={{ color: "var(--Webprimary)" }}>
                                        Create here
                                    </Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default observer(LoginPage);

import { Header } from "antd/lib/layout/layout"
import { Observer } from "mobx-react-lite"
import { Dropdown, Menu } from 'antd';
import { useStore } from "../../use-stores";
import './header.css';
import { DownOutlined, SettingOutlined, LogoutOutlined, ApiOutlined } from "@ant-design/icons";
import Avatar from "src/assets/avatar.svg";
import { useNavigate } from "react-router-dom";

export const HeaderLayout: React.FC<{ title: string }> = ({ title }) => {
    const navigate = useNavigate();
    const logout = () => {
        tokenStore.logout();
        generalStore.setSelectedMenuItem();
        navigate('/');
    }
    const menu = (
        <Menu
            items={[
                { key: 'profile', icon: <SettingOutlined />, label: (<a rel="noopener noreferrer" href="/profile">Profile Settings</a>), },
                { key: 'account', icon: <ApiOutlined />, label: (<a rel="noopener noreferrer" href="/account">Manage Account</a>), },
                { key: 'logout', icon: <LogoutOutlined />, label: (<a rel="noopener noreferrer" onClick={logout}>Logout</a>), }
            ]}
        />
    );

    const { generalStore, tokenStore } = useStore();
    return <Observer>
        {() => {
            return <Header className="Admin-header" style={{ /*backgroundColor: "#001529"*/ backgroundColor: 'rgb(255 255 255)' }}>
                <img className="logo" src="logo128.png" width={50} />
                {/*<Image rootClassName={"logo"} width={50} src="logo128.png" />*/}
                <span style={{ paddingLeft: generalStore.isMenuOpen ? "200px" : "70px" }} className="Admin-header-page-header">{title}</span>
                <Dropdown overlay={menu}>
                    {tokenStore.user && <div className="Admin-header-profile">
                        {/* <BellFilled style={{ color: "#90A0E3", fontSize: "16px" }} /> */}
                        <span className="Admin-header-profile-img">
                            <img src={Avatar} alt="avatar" />
                        </span>
                        <span className="flex-align-center" style={{ /*color: "white"*/ color: "var(--Adminprimary)" }}>
                            {tokenStore.user?.firstname} {tokenStore.user?.lastname}
                            <a onClick={e => e.preventDefault()}>
                                <DownOutlined className="ml-2" style={{ fontSize: "12px", color: "white" }} />
                            </a>
                        </span>
                    </div>}
                </Dropdown>
            </Header>
        }}
    </Observer>
}
import React, { useEffect, useState } from "react";
import { Row, Col, Cascader, Divider, InputNumber, Button, Form, Input, Select, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Observer } from 'mobx-react-lite';
import { useStore } from "../../use-stores";
import { PageLayout } from "../../layouts/page/page";
import { toJS } from "mobx";
import { Notify, NotifyDark } from "../../utils/snippet";
import "./profile.css";
import { useNavigate } from "react-router-dom";

const prefixSelector = (
    <Form.Item name="country_code" noStyle initialValue="+91">
        <Select style={{ width: 70 }} disabled>
            <Select.Option selected value="+91">
                +91
            </Select.Option>
        </Select>
    </Form.Item>
);

const ProfilePage: React.FC = () => {
    /*********************************************************************INITIAT PAGE */
    const { vendorStore, generalStore, tokenStore } = useStore();
    const [basicFormState, setBasicFormState] = useState(false)
    const [credentialFormState, setCredentialFormState] = useState(false)
    const user: any = tokenStore.getUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (!toJS(generalStore.residence)) {
            generalStore.getCountries().then((_d: any) => {
                if (_d && _d.status === "error") NotifyDark('warning', 'Warning', 'Countries Not stored properly')
            })
        }
        if (user && !vendorStore.accountInfo && !toJS(vendorStore.accountInfo)) {
            setBasicFormState(true)
            setCredentialFormState(true)
            vendorStore.getAccountInfo(user.id).then((_d: any) => {
                if (_d && _d.status === "error") {
                    NotifyDark(_d.status, "Failed", _d.message);
                }
            }).finally(() => {
                setBasicFormState(false)
                setCredentialFormState(false)
            })
        }
    }, [])
    /*********************************************************************BASIC FORM */
    const setAccountInfo = async (values: any) => {
        setBasicFormState(true)
        let _post = {
            vendor_id: user.vendor_id,
            vendor_user_id: user.id,
            vendor: {
                address_line1: values.address_line1,
                address_line2: values.address_line2,
                companyname: values.companyname,
                city: values.residence[2],
                state: values.residence[1],
                country: values.residence[0],
                pincode: values.pincode,
                logo_url: values.logo_url,
                updated_by: user.id,
                website: values.website
            },
            user: {
                firstname: values.firstname,
                lastname: values.lastname,
                country_code: values.country_code,
                updated_by: user.id
            }
        }
        await vendorStore.doSetAccountInfo(_post).then((_d: any) => {
            if (_d && _d.status === "error") {
                NotifyDark(_d.status, "Failed", _d.message);
            } else NotifyDark(_d.status, "Success", _d.message);
        }).finally(() => {
            setBasicFormState(false)
        })
    }
    /*********************************************************************CHANGE PASSWORD */
    const changePassword = async (values: any) => {
        setCredentialFormState(true)
        let _post = {
            ...values,
            vendor_user_id: user.id
        }
        await vendorStore.changePassword(_post).then((_d: any) => {
            if (_d && _d.status === "error") {
                NotifyDark(_d.status, "Failed", _d.message);
            } else Notify(_d.status, "success", 'Password Changed Successfully');
        }).finally(() => {
            setCredentialFormState(false)
        })
    }
    /*********************************************************************COMMON */
    const onFailure = (errorInfo: any) => {
        console.error(errorInfo)
    }

    return <Observer>{() => {
        return (
            <PageLayout title="Account Settings">
                {toJS(vendorStore.accountInfo) ? (
                    <div className="profilepg">
                        <div className="app-container">
                            <Form onFinish={setAccountInfo} disabled={basicFormState} onFinishFailed={onFailure} initialValues={{ remember: true }} name="basic" layout="vertical"
                                labelCol={{ span: 24 }}
                                labelAlign="left"
                                wrapperCol={{ span: 24 }}
                                autoComplete="off"
                            >
                                <Row gutter={[12, 12]}><Col span={24}><h2 className='app-h2-header'>Basic Details</h2></Col></Row>
                                <Divider />
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name="firstname" initialValue={vendorStore.accountInfo.firstname} label={<label className="app-custom-label">First Name</label>} rules={[{ required: true, message: "Please input your First Name" }]}>
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name="lastname" initialValue={vendorStore.accountInfo.lastname} label={<label className="app-custom-label">Last Name</label>} rules={[{ required: true, message: "Please input your Last Name" }]}>
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]} align="bottom">
                                    {vendorStore.accountInfo.mobile_verified ?
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item name="mobile" initialValue={vendorStore.accountInfo.mobile} label={<label className="app-custom-label">Mobile No</label>} rules={[{ required: true, message: "Please input your Mobile No" }]}>
                                                <InputNumber disabled controls={false} type="number" addonBefore={prefixSelector} className="app-form-input app-form-number" />
                                            </Form.Item>
                                        </Col>
                                        :
                                        <>
                                            <Col xs={24} sm={16} md={8} lg={8} xl={8} xxl={8}>
                                                <Form.Item name="mobile" initialValue={vendorStore.accountInfo.mobile} label={<label className="app-custom-label">Mobile No</label>} rules={[{ required: true, message: "Please input your Mobile No" }]}>
                                                    <InputNumber disabled controls={false} type="number" addonBefore={prefixSelector} className="app-form-input app-form-number" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                                <Form.Item >
                                                    <Button onClick={() => {
                                                        navigate("/verifyaccount", {
                                                            state: { type: "mobile", data: vendorStore.accountInfo.mobile }
                                                        })
                                                    }} target="_blank" className='app-plain-btn'>Verify mobile number <EditOutlined className='app-icon' /></Button>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    }
                                    {vendorStore.accountInfo.email_verified ?
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item name="email" initialValue={vendorStore.accountInfo.email} label={<label className="app-custom-label">Email</label>} rules={[{ required: true, message: "Please input your Email ID" }]}>
                                                <Input type="email" disabled className="app-form-input" />
                                            </Form.Item>
                                        </Col>
                                        :
                                        <>
                                            <Col xs={24} sm={16} md={8} lg={8} xl={8} xxl={8}>
                                                <Form.Item name="email" initialValue={vendorStore.accountInfo.email} label={<label className="app-custom-label">Email</label>} rules={[{ required: true, message: "Please input your Email ID" }]}>
                                                    <Input type="email" disabled className="app-form-input" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                                <Form.Item >
                                                    <Button onClick={() => {
                                                        navigate("/verifyaccount", {
                                                            state: { type: "email", data: vendorStore.accountInfo.email }
                                                        })
                                                    }} target="_blank" className='app-plain-btn'>Verify email-ID <EditOutlined className='app-icon' /></Button>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    }
                                </Row>
                                <Divider />
                                <Row gutter={[12, 12]}><Col span={24}><h2 className='app-h2-header'>Company Details</h2></Col></Row>
                                <Divider />
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                        <Form.Item name="companyname" initialValue={vendorStore.accountInfo.vendor.companyname} label={<label className="app-custom-label">Company Name</label>} rules={[{ required: true, message: "Please input your Company Name" }]}>
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name="logo_url" initialValue={vendorStore.accountInfo.vendor.logo_url} label={<label className="app-custom-label">Logo URL</label>} rules={[{ required: false }, { type: 'url' }, { type: 'string', min: 6 }]}>
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name="website" initialValue={vendorStore.accountInfo.vendor.website} label={<label className="app-custom-label">Website URL</label>} rules={[{ required: false }, { type: 'url' }, { type: 'string', min: 6 }]}>
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name="address_line1" initialValue={vendorStore.accountInfo.vendor.address_line1} label={<label className="app-custom-label">Address Line 1</label>} rules={[{ required: true, message: "Please input your Address Line 1!" }]}>
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item name="address_line2" initialValue={vendorStore.accountInfo.vendor.address_line2} label={<label className="app-custom-label">Address Line 2</label>} >
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                        <Form.Item name="residence" initialValue={[vendorStore.accountInfo.vendor.country, vendorStore.accountInfo.vendor.state, vendorStore.accountInfo.vendor.city]} label={<label className="app-custom-label">Country / State / City</label>} rules={[{ type: "array", required: true, message: "Please select your habitual residence!" }]}>
                                            <Cascader showSearch options={generalStore.residence} className="app-form-input app-form-search-option" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                        <Form.Item name="pincode" initialValue={vendorStore.accountInfo.vendor.pincode} label={<label className="app-custom-label">Pincode</label>} rules={[{ required: true, message: "Please input your username!" }]}>
                                            <Input className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row>
                                    <Col span={24}>
                                        <Button htmlType="submit" className="app-custom-btn" style={{ margin: 'unset' }}>
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                            </Form>
                        </div>

                        <div className="app-container">
                            <Form onFinish={changePassword} disabled={credentialFormState} onFinishFailed={onFailure} initialValues={{ remember: true }} name="change_password" layout="vertical"
                                labelCol={{ span: 24 }}
                                labelAlign="left"
                                wrapperCol={{ span: 24 }}
                                autoComplete="off">
                                <Row gutter={[12, 12]}><Col span={24}><h2 className='app-h2-header'>Change Password</h2></Col></Row>
                                <Divider />
                                <Row gutter={[10, 10]}>
                                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={8}>
                                        <Form.Item name="old_password" label={<label className="app-custom-label">Old Password</label>} rules={[{ required: true, message: "Please input your Old Password" }]}>
                                            <Input.Password className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={8}>
                                        <Form.Item name="new_password" label={<label className="app-custom-label">Password</label>} rules={[{ min: 8, required: true, message: "Please input your password. Minimum 8 characters!" }]}>
                                            <Input.Password max={8} className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={8}>
                                        <Form.Item name="confirmpassword" label={<label className="app-custom-label">Confirm Password</label>} dependencies={["new_password"]}
                                            rules={[
                                                { required: true, message: "Please input your password!" },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue("new_password") === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("The two passwords that you entered do not match!"));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password className="app-form-input" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item hidden name="email" initialValue={vendorStore.accountInfo.email}>
                                    <Input type="email" className="app-form-input" />
                                </Form.Item>
                                <Divider />
                                <Row>
                                    <Col span={24}>
                                        <Button htmlType="submit" className="app-custom-btn" style={{ margin: 'unset' }}>
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                            </Form>
                        </div>
                    </div>
                ) : (
                    <div style={{
                        height: '90vh !important',
                        textAlign: 'center',
                        borderRadius: '4px'
                    }}>
                        <Spin tip="Loading..." />
                    </div>
                )}
            </PageLayout>
        )
    }}</Observer>
}

export default ProfilePage;